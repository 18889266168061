// Name:            Popover
//
// Component:       `popover`
//
// Modifiers:       `popover-primary`
//                  `popover-success`
//                  `popover-info`
//                  `popover-warning`
//                  `popover-danger`
//                  `popover-rotate`
//                  `popover-scale`
//
// Markup:
//
// <!-- popover -->
// <div class="popover">
//   <div class="arrow"></div>
//   <h3 class="popover-title"></h3>
//   <div class="popover-content"></div>
// </div>
//
// ========================================================================

// Variables
// ========================================================================
$popover-primary-color  : $inverse !default;
$popover-primary-bg     : $brand-primary !default;
$popover-primary-border : $brand-primary !default;

$popover-success-color  : $inverse !default;
$popover-success-bg     : $brand-success !default;
$popover-success-border : $brand-success !default;

$popover-info-color     : $inverse !default;
$popover-info-bg        : $brand-info !default;
$popover-info-border    : $brand-info !default;

$popover-warning-color  : $inverse !default;
$popover-warning-bg     : $brand-warning !default;
$popover-warning-border : $brand-warning !default;

$popover-danger-color   : $inverse !default;
$popover-danger-bg      : $brand-danger !default;
$popover-danger-border  : $brand-danger !default;

// Mixins
// ========================================================================

@mixin popover-variant($color, $bg, $border){
  .popover-title {
    color: $color;
    background-color: $bg;
    border-color: $border;
  }

  
  &.bs-tether-element-attached-top .popover-arrow,
  &.popover-bottom .popover-arrow {
    border-bottom-color: $border;
    &:after {
      border-bottom-color: $bg;
    }
  }
}


// Component: Popover
// ========================================================================

.popover {
  padding: 0;

  box-shadow: $popover-shadow;

  &.bs-tether-element-attached-top,
  &.popover-bottom {
    > .popover-arrow:after {
      border-bottom-color: $popover-title-bg;
    }
  }
  &-content {
    padding: 20px;
  }
}


// Color Modifier
// ========================================================================

// Modifier: `popover-primary`
// -------------------------

.popover-primary {
  @include popover-variant($popover-primary-color, $popover-primary-bg, $popover-primary-border);
}

// Modifier: `popover-success`
// -------------------------

.popover-success {
  @include popover-variant($popover-success-color, $popover-success-bg, $popover-success-border);
}

// Modifier: `popover-info`
// -------------------------

.popover-info {
  @include popover-variant($popover-info-color, $popover-info-bg, $popover-info-border);
}

// Modifier: `popover-warning`
// -------------------------

.popover-warning {
  @include popover-variant($popover-warning-color, $popover-warning-bg, $popover-warning-border);
}

// Modifier: `popover-danger`
// -------------------------

.popover-danger {
  @include popover-variant($popover-danger-color, $popover-danger-bg, $popover-danger-border);
}

// Popover Animation
// ========================================================================

// Modifier: `popover-rotate`
// -------------------------

// .popover-rotate {
//   + .popover {
//     animation: popover-rotate3d 1s ease 0.1s forwards;
//     opacity: 0;
//   }
// }

// @-webkit-keyframes popover-rotate3d {
//   0% {
//     opacity: 0;
//     -webkit-transform: rotate(15deg);
//             transform: rotate(15deg);
//   }
//   100% {
//     opacity: 1;
//     -webkit-transform: rotate(0deg);
//             transform: rotate(0deg);
//   }
// }

// @keyframes popover-rotate3d {
//   0% {
//     opacity: 0;
//     transform: rotate(15deg);
//   }
//   100% {
//     opacity: 1;
//     transform: rotate(0deg);
//   }
// }

// Modifier: `popover-scale`
// -------------------------

// .popover-scale {
//   + .popover {
//     animation: popover-scale3d 1s ease 0s forwards;
//   }
// }

// @-webkit-keyframes popover-scale3d {
//   0% {
//     opacity: 0;
//     -webkit-transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
//             transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
//   }
//   100% {
//     opacity: 1;
//     -webkit-transform: scale3d(1, 1, 1) translate(50%, 50%);
//             transform: scale3d(1, 1, 1) translate(50%, 50%);
//   }
// }

// @keyframes popover-scale3d {
//   0% {
//     opacity: 0;
//     transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
//   }
//   100% {
//     opacity: 1;
//     transform: scale3d(1, 1, 1) translate(50%, 50%);
//   }
// }

