// Name:      Blocks
//
// Component:     `blocks`
//
// Modifiers:     `blocks-2`
//                `blocks-3`
//                `blocks-4`
//                `blocks-5`
//                `blocks-6`
//
// ========================================================================

// Variables
// ========================================================================

$blocks-grid-gutter-width:         $grid-gutter-width !default;
$blocks-margin-bottom:             $blocks-grid-gutter-width !default;
$blocks-columns: (100: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6) !default;

// Mixins
// ========================================================================
@mixin make-block-row($gutter: $blocks-grid-gutter-width){
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
  @include clearfix();
}

@mixin make-blocks-column($gutter: $blocks-grid-gutter-width){
  position: relative;
  float: left;

  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}

//=========================v3
// @mixin make-blocks-column-width($columns){
//   // width: percentage((1 / $columns));
//   $rate: ((1 / $columns)) !default;
//   width: percentage($rate);
// }

// @mixin make-blocks-media-column-width($columns, $screen-min){
//   @media (min-width: $screen-min) {
//     // width: percentage((1 / $columns));
//     $rate: ((1 / $columns)) !default;
//     width: percentage($rate);
//   }
// }
//=========================v3 end

@mixin make-blocks-column-width($columns: $blocks-columns, $gutter: $blocks-grid-gutter-width, $breakpoints: $grid-breakpoints){  
  @each $i in map-keys($columns) {
    .blocks-#{$i} > li {
      $rate1: ((1 / map-get($blocks-columns, $i))) !default;
      width: percentage($rate1);
    }
    @each $j in map-keys($breakpoints) {      
      @include media-breakpoint-up($j) {
        .blocks-#{$j}-#{$i} > li {
          $rate2: ((1 / map-get($blocks-columns, $i))) !default;
          width: percentage($rate2);
        }
      }
    }
  }
}

// Component: Blocks
// ========================================================================
a:focus {
  outline: none;
}

.blocks, [class*="blocks-"] {
  list-style: none;
  padding: 0;
  margin: 0;

  @include make-block-row();

  & > li {
    margin-bottom: $blocks-margin-bottom;

    @include make-blocks-column();
  }

  &.no-space {
    margin: 0;

    > li {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;
    }
  }
}

.blocks-2 > li:nth-child(2n+3),
.blocks-3 > li:nth-child(3n+4),
.blocks-4 > li:nth-child(4n+5),
.blocks-5 > li:nth-child(5n+6),
.blocks-6 > li:nth-child(6n+7),
.block-first {
  clear: both;
}

// Size Modifier
// ========================================================================
@include make-blocks-column-width();

// .blocks-100 > li { @include make-blocks-column-width(1); }
// .blocks-2   > li { @include make-blocks-column-width(2); }
// .blocks-3   > li { @include make-blocks-column-width(3); }
// .blocks-4   > li { @include make-blocks-column-width(4); }
// .blocks-5   > li { @include make-blocks-column-width(5); }
// .blocks-6   > li { @include make-blocks-column-width(6); }

// .blocks-xs-100  > li { @include make-blocks-media-column-width(1, $screen-xs-min); }
// .blocks-xs-2    > li { @include make-blocks-media-column-width(2, $screen-xs-min); }
// .blocks-xs-3    > li { @include make-blocks-media-column-width(3, $screen-xs-min); }
// .blocks-xs-4    > li { @include make-blocks-media-column-width(4, $screen-xs-min); }
// .blocks-xs-5    > li { @include make-blocks-media-column-width(5, $screen-xs-min); }
// .blocks-xs-6    > li { @include make-blocks-media-column-width(6, $screen-xs-min); }

// .blocks-sm-100  > li { @include make-blocks-media-column-width(1, $screen-sm-min); }
// .blocks-sm-2    > li { @include make-blocks-media-column-width(2, $screen-sm-min); }
// .blocks-sm-3    > li { @include make-blocks-media-column-width(3, $screen-sm-min); }
// .blocks-sm-4    > li { @include make-blocks-media-column-width(4, $screen-sm-min); }
// .blocks-sm-5    > li { @include make-blocks-media-column-width(5, $screen-sm-min); }
// .blocks-sm-6    > li { @include make-blocks-media-column-width(6, $screen-sm-min); }

// .blocks-md-100  > li { @include make-blocks-media-column-width(1, $screen-md-min); }
// .blocks-md-2    > li { @include make-blocks-media-column-width(2, $screen-md-min); }
// .blocks-md-3    > li { @include make-blocks-media-column-width(3, $screen-md-min); }
// .blocks-md-4    > li { @include make-blocks-media-column-width(4, $screen-md-min); }
// .blocks-md-5    > li { @include make-blocks-media-column-width(5, $screen-md-min); }
// .blocks-md-6    > li { @include make-blocks-media-column-width(6, $screen-md-min); }

// .blocks-lg-100  > li { @include make-blocks-media-column-width(1, $screen-lg-min); }
// .blocks-lg-2    > li { @include make-blocks-media-column-width(2, $screen-lg-min); }
// .blocks-lg-3    > li { @include make-blocks-media-column-width(3, $screen-lg-min); }
// .blocks-lg-4    > li { @include make-blocks-media-column-width(4, $screen-lg-min); }
// .blocks-lg-5    > li { @include make-blocks-media-column-width(5, $screen-lg-min); }
// .blocks-lg-6    > li { @include make-blocks-media-column-width(6, $screen-lg-min); }

// .blocks-xlg-100 > li { @include make-blocks-media-column-width(1, $screen-xlg-min); }
// .blocks-xlg-2   > li { @include make-blocks-media-column-width(2, $screen-xlg-min); }
// .blocks-xlg-3   > li { @include make-blocks-media-column-width(3, $screen-xlg-min); }
// .blocks-xlg-4   > li { @include make-blocks-media-column-width(4, $screen-xlg-min); }
// .blocks-xlg-5   > li { @include make-blocks-media-column-width(5, $screen-xlg-min); }
// .blocks-xlg-6   > li { @include make-blocks-media-column-width(6, $screen-xlg-min); }


