// Name:            List groups
//
// Component:       `list-group`
//
// Modifiers:       `list-group.bg-inherit`
//                  `list-group-bordered`
//                  `list-group-gap`
//                  `list-group-full`
//                  `list-group-item-dark`
//
// ========================================================================

// Variables
// ========================================================================

$list-group-active-color                 : $brand-primary !default;

$list-group-link-disabled-color          : $disabled-color-base !default;
$list-group-link-disabled-bg             : $gray-lightest !default;

$list-group-link-active-color            : $color-white !default;
$list-group-link-active-bg               : $brand-primary !default;

$list-group-item-icon-margin-right       : 10px !default;

$list-group-bg-inherit-item-border       : rgba(0, 0, 0, 0.075) !default;
$list-group-bg-inherit-item-hover-bg     : rgba(0, 0, 0, 0.075) !default;

$list-group-bordered-border              : $border-color-base !default;
$list-group-bordered-active-color        : $component-active-color !default;
$list-group-bordered-active-bg           : $component-active-bg !default;
$list-group-bordered-active-border       : $list-group-bordered-active-bg !default;

$list-group-gap-item-margin-bottom       : 2px !default;

$list-group-media-heading-font-size      : $font-size-base !default;

// Component: List groups
// ========================================================================

.list-group {
  margin-bottom: 20px; // add from---v3

  .media {
    padding: 2px 0;
    border-bottom: 0;
    .pull-xs-left,
    .media-left {
      padding-right: 20px;
    }
    .pull-xs-right,
    .media-right {
      padding-left: 20px;
    }
    .pull-xs-right {
      .status {
        margin-top: 15px;
        margin-right: 5px;
      }
    }
    .media-heading {
      font-size: $list-group-media-heading-font-size;
    }
  }
}

.list-group-full {
  > .list-group-item {
    padding-left: 0;
    padding-right: 0;
  }
}

a.list-group-item {
  border-radius: $border-radius;
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    color: $list-group-link-disabled-color;
    background-color: $list-group-link-disabled-bg;
  }
  &.active,
  &.active:hover,
  &.active:focus {
    color: $list-group-link-active-color;
    background-color: $list-group-link-active-bg;
  }
}

.list-group-item {
  .icon {
    margin-right: $list-group-item-icon-margin-right;
  }
}



// Modifier: `list-group.bg-inherit`
// -------------------------

.list-group.bg-inherit {
  border-radius: $border-radius;
  .list-group-item {
    background-color: transparent;
    border-bottom-color: $list-group-bg-inherit-item-border;
    &:last-child {
      border-bottom-color: transparent;
    }
    &:hover {
      background-color: $list-group-bg-inherit-item-hover-bg;
      border-color: transparent;
    }
  }
}


// Modifier: `list-group-bordered`
// -------------------------

.list-group-bordered {
  .list-group-item {
    border-color: $list-group-bordered-border;
    &.active,
    &.active:hover,
    &.active:focus {
      color: $list-group-bordered-active-color;
      background-color: $list-group-bordered-active-bg;
      border-color: $list-group-bordered-active-border;
    }
  }
}

// Modifier: `list-group-dividered`
// -------------------------

.list-group-dividered {
  .list-group-item, {
    border-top-color: $list-group-bordered-border;

    &.active:hover {
      border-top-color: $list-group-bordered-border;
    }
  }

  .list-group-item:last-child {
    border-bottom-color: $list-group-bordered-border;
  }

  .list-group-item:first-child {
    border-top-color: $list-group-border-color;
    &.active:hover {
      border-top-color: $list-group-border-color;
    }
  }
}


// Modifier: `list-group-gap`
// -------------------------

.list-group-gap {
  .list-group-item {
    margin-bottom: $list-group-gap-item-margin-bottom;
    border-radius: $border-radius;
  }
}

// Modifier: `list-group-full`
// -------------------------

.list-group-full {
  .list-group-item {
    padding-right: 0;
    padding-left: 0;
  }
}

// add from---v3
.list-group-item.active, 
.list-group-item.active:focus, 
.list-group-item.active:hover {
  background-color: $list-group-active-bg;
  border-color: $list-group-active-border;
  color: $list-group-active-color;
}

.list-group-item {
  border: 1px solid transparent;
}
// add end

// Color Modifier
// ========================================================================

// Modifier: `list-group-item-dark`
// -------------------------

@include list-group-item-variant(dark, $state-dark-bg, $state-dark-text);
