// Patch for Font Awesome

$fa-css-prefix: fa !default;
$fa-inverse: #fff !default;

.#{$fa-css-prefix}-rotate-90 {
  transform: rotate(90deg) !important;
}

.#{$fa-css-prefix}-rotate-180 {
  transform: rotate(180deg) !important;
}

.#{$fa-css-prefix}-rotate-270 {
  transform: rotate(270deg) !important;
}

.#{$fa-css-prefix}-flip-horizontal {
  transform: scaleX(-1) !important;
}

.#{$fa-css-prefix}-flip-vertical {
  transform: scaleY(-1) !important;
}

.#{$fa-css-prefix}-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.#{$fa-css-prefix}-stack-1x, .#{$fa-css-prefix}-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.#{$fa-css-prefix}-stack-1x { line-height: inherit; }
.#{$fa-css-prefix}-stack-2x { font-size: 2em; }
.#{$fa-css-prefix}-inverse { color: $fa-inverse; }

.#{$fa-css-prefix}-spin {
  animation-name: fa-spinner-animation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.#{$fa-css-prefix}-reverse {
  animation-direction: reverse;
}

.#{$fa-css-prefix}-pulse {
  animation-timing-function: steps(8);
  animation-duration: 1s;
}

@keyframes fa-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
