// Name:            labels
//
// Component:       `label`
//
// Modifiers:       `label-outline`
//                  `label-primary`
//                  `label-success`
//                  `label-info`
//                  `label-warning`
//                  `label-danger`
//                  `label-dark`
//                  `label-lg`
//                  `label-sm`
//                  `label-radius`
//
// Markup:
//
// <!-- label -->
// <span class="label">...</span>
//
// ========================================================================


// Variables
// ========================================================================

$label-bg:                    $bg-color-base !default;
$label-border-radius:         0.3em !default;
$label-padding:               .5em 1em !default;

$label-pill-padding:          3px 6px !default;
$label-pill-up-margin:        0 -0.8em !default;
$label-pill-active-color:     $gray-darker !default;
$label-pill-active-bg:        $brand-default !default;

$label-default-color:         $text-color !default;
// $label-default-font-size:     $font-size-base !default;

$label-lg-font-size:          16px !default;

$label-sm-font-size:          10px !default;
$label-sm-padding:            .1em .5em .1em !default;

$label-round-border-radius:   1em !default;

$label-default-hover-color:   $gray !default;
$label-default-hover-bg:      $brand-default-hover !default;

$label-primary-hover-bg:      $brand-primary-hover !default;

$label-success-hover-bg:      $brand-success-hover !default;

$label-info-hover-bg:         $brand-info-hover !default;

$label-warning-hover-bg:      $brand-warning-hover !default;

$label-danger-hover-bg:       $brand-danger-hover !default;

$label-dark-hover-bg:         $brand-dark-hover !default;

// Component: Label
// ========================================================================

.label {
  // font-size: $label-default-font-size;
  padding: $label-padding;
  font-weight: $label-font-weight;
  border-radius: $label-border-radius;

  &.label-outline {
    color: $label-bg;
    background-color: transparent;
    border-color: $label-bg;
  }
}


// Alternate styles: `label-outline`
// -------------------------

.label-outline {
  border: 1px solid transparent;
}

// Modifier: `label-round`
// -------------------------

.label-round {
  border-radius: $label-round-border-radius;
}

// Modifier: `label-pill`
// -------------------------
.label-pill {
  padding: $label-pill-padding;
  @include border-radius(10rem);

  // Empty badges collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  &.up {
    position: relative;
    top: -10px;
    margin: $label-pill-up-margin;
    border-radius: 15px;
  }

  // Quick fix for label-pill in buttons
  .btn & {
    top: 0;
  }

  .btn-xs &,
  .btn-group-xs > .btn & {
    top: 0;
    padding: 1px 5px;
  }

  // Hover state, but only for links
  a & {
    &:hover,
    &:focus {
      text-decoration: none;
      cursor: pointer;
    }
  }

  .list-group-item > & {
    float: right;
  }

  .list-group-item > & + & {
    margin-right: 5px;
  }

  .nav-pills > li > a > & {
    margin-left: 3px;
  }
}

// Color Modifier
// ========================================================================

// Mixins
// ========================================================================

@mixin label-styles-variant($color, $hover-color){
  background-color: $color;

  &[href] {
    &:hover,
    &:focus {
      background-color: $hover-color;
    }
  }

  &.label-outline {
    color: $color;
    background-color: transparent;
    border-color: $color;
  }
}

// Modifier: `label-default`
// -------------------------

.label-default {
  color: $label-default-color;

  @include label-styles-variant($label-default-bg, $label-default-hover-bg);

  &[href] {
    &:hover,
    &:focus {
      color: $label-default-hover-color;
    }
  }

  &.label-outline {
    color: $label-default-color;
  }
}

// Modifier: `label-primary`
// -------------------------

.label-primary {
  @include label-styles-variant($label-primary-bg, $label-primary-hover-bg);
}

// Modifier: `label-success`
// -------------------------

.label-success {
  @include label-styles-variant($label-success-bg, $label-success-hover-bg);
}

// Modifier: `label-info`
// -------------------------

.label-info {
  @include label-styles-variant($label-info-bg, $label-info-hover-bg);
}

// Modifier: `label-warning`
// -------------------------

.label-warning {
  @include label-styles-variant($label-warning-bg, $label-warning-hover-bg);
}

// Modifier: `label-danger`
// -------------------------

.label-danger {
  @include label-styles-variant($label-danger-bg, $label-danger-hover-bg);
}

// Modifier: `label-dark`
// -------------------------

.label-dark {
  @include label-styles-variant($label-dark-bg, $label-dark-hover-bg);
}

// Size Modifier
// ========================================================================

// Modifier: `label-lg`
// -------------------------

.label-lg {
  font-size: $label-lg-font-size;
}

// Modifier: `label-sm`
// -------------------------

.label-sm {
  padding: $label-sm-padding;
  font-size: $label-sm-font-size;
}
