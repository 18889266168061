// Icons
// -------------------------
.#{$wb-css-prefix}-dashboard:before {
	content: $wb-var-dashboard;
}
.#{$wb-css-prefix}-inbox:before,
.#{$wb-css-prefix}-preparationRuns:before {
	content: $wb-var-inbox;
}
.#{$wb-css-prefix}-cloud:before {
	content: $wb-var-cloud;
}
.#{$wb-css-prefix}-bell:before {
	content: $wb-var-bell;
}
.#{$wb-css-prefix}-book:before {
	content: $wb-var-book;
}
.#{$wb-css-prefix}-bookmark:before {
	content: $wb-var-bookmark;
}
.#{$wb-css-prefix}-tag:before {
	content: $wb-var-tag;
}
.#{$wb-css-prefix}-library:before,
.#{$wb-css-prefix}-productPacks:before,
.#{$wb-css-prefix}-products:before {
	content: $wb-var-library;
}
.#{$wb-css-prefix}-share:before {
	content: $wb-var-share;
}
.#{$wb-css-prefix}-reply:before {
	content: $wb-var-reply;
}
.#{$wb-css-prefix}-refresh:before {
	content: $wb-var-refresh;
}
.#{$wb-css-prefix}-move:before {
	content: $wb-var-move;
}
.#{$wb-css-prefix}-chat:before {
	content: $wb-var-chat;
}
.#{$wb-css-prefix}-chat-working:before {
	content: $wb-var-chat-working;
}
.#{$wb-css-prefix}-chat-text:before {
	content: $wb-var-chat-text;
}
.#{$wb-css-prefix}-chat-group:before {
	content: $wb-var-chat-group;
}
.#{$wb-css-prefix}-envelope:before {
	content: $wb-var-envelope;
}
.#{$wb-css-prefix}-envelope-open:before {
	content: $wb-var-envelope-open;
}
.#{$wb-css-prefix}-user:before {
	content: $wb-var-user;
}
.#{$wb-css-prefix}-user-circle:before {
	content: $wb-var-user-circle;
}
.#{$wb-css-prefix}-users:before {
	content: $wb-var-users;
}
.#{$wb-css-prefix}-user-add:before {
	content: $wb-var-user-add;
}
.#{$wb-css-prefix}-grid-9:before {
	content: $wb-var-grid-9;
}
.#{$wb-css-prefix}-grid-4:before {
	content: $wb-var-grid-4;
}
.#{$wb-css-prefix}-menu:before {
	content: $wb-var-menu;
}
.#{$wb-css-prefix}-layout:before {
	content: $wb-var-layout;
}
.#{$wb-css-prefix}-fullscreen:before {
	content: $wb-var-fullscreen;
}
.#{$wb-css-prefix}-fullscreen-exit:before {
	content: $wb-var-fullscreen-exit;
}
.#{$wb-css-prefix}-expand:before {
	content: $wb-var-expand;
}
.#{$wb-css-prefix}-contract:before {
	content: $wb-var-contract;
}
.#{$wb-css-prefix}-arrow-expand:before {
	content: $wb-var-arrow-expand;
}
.#{$wb-css-prefix}-arrow-shrink:before {
	content: $wb-var-arrow-shrink;
}
.#{$wb-css-prefix}-desktop:before,
.#{$wb-css-prefix}-fillInTracking:before {
	content: $wb-var-desktop;
}
.#{$wb-css-prefix}-mobile:before {
	content: $wb-var-mobile;
}
.#{$wb-css-prefix}-signal:before {
	content: $wb-var-signal;
}
.#{$wb-css-prefix}-power:before {
	content: $wb-var-power;
}
.#{$wb-css-prefix}-more-horizontal:before {
	content: $wb-var-more-horizontal;
}
.#{$wb-css-prefix}-more-vertical:before {
	content: $wb-var-more-vertical;
}
.#{$wb-css-prefix}-globe:before {
	content: $wb-var-globe;
}
.#{$wb-css-prefix}-map:before {
	content: $wb-var-map;
}
.#{$wb-css-prefix}-flag:before {
	content: $wb-var-flag;
}
.#{$wb-css-prefix}-pie-chart:before {
	content: $wb-var-pie-chart;
}
.#{$wb-css-prefix}-stats-bars:before {
	content: $wb-var-stats-bars;
}
.#{$wb-css-prefix}-pluse:before {
	content: $wb-var-pluse;
}
.#{$wb-css-prefix}-home:before {
	content: $wb-var-home;
}
.#{$wb-css-prefix}-shopping-cart:before {
	content: $wb-var-shopping-cart;
}
.#{$wb-css-prefix}-payment:before {
	content: $wb-var-payment;
}
.#{$wb-css-prefix}-briefcase:before {
	content: $wb-var-briefcase;
}
.#{$wb-css-prefix}-search:before {
	content: $wb-var-search;
}
.#{$wb-css-prefix}-zoom-in:before {
	content: $wb-var-zoom-in;
}
.#{$wb-css-prefix}-zoom-out:before {
	content: $wb-var-zoom-out;
}
.#{$wb-css-prefix}-download:before {
	content: $wb-var-download;
}
.#{$wb-css-prefix}-upload:before {
	content: $wb-var-upload;
}
.#{$wb-css-prefix}-sort-asc:before {
	content: $wb-var-sort-asc;
}
.#{$wb-css-prefix}-sort-des:before {
	content: $wb-var-sort-des;
}
.#{$wb-css-prefix}-graph-up:before {
	content: $wb-var-graph-up;
}
.#{$wb-css-prefix}-graph-down:before {
	content: $wb-var-graph-down;
}
.#{$wb-css-prefix}-replay:before {
	content: $wb-var-replay;
}
.#{$wb-css-prefix}-edit:before {
	content: $wb-var-edit;
}
.#{$wb-css-prefix}-pencil:before {
	content: $wb-var-pencil;
}
.#{$wb-css-prefix}-rubber:before {
	content: $wb-var-rubber;
}
.#{$wb-css-prefix}-crop:before {
	content: $wb-var-crop;
}
.#{$wb-css-prefix}-eye:before {
	content: $wb-var-eye;
}
.#{$wb-css-prefix}-eye-close:before {
	content: $wb-var-eye-close;
}
.#{$wb-css-prefix}-image:before {
	content: $wb-var-image;
}
.#{$wb-css-prefix}-gallery:before {
	content: $wb-var-gallery;
}
.#{$wb-css-prefix}-video:before {
	content: $wb-var-video;
}
.#{$wb-css-prefix}-camera:before {
	content: $wb-var-camera;
}
.#{$wb-css-prefix}-folder:before {
	content: $wb-var-folder;
}
.#{$wb-css-prefix}-clipboard:before {
	content: $wb-var-clipboard;
}
.#{$wb-css-prefix}-order:before,
.#{$wb-css-prefix}-orders:before {
	content: $wb-var-order;
}
.#{$wb-css-prefix}-file:before {
	content: $wb-var-file;
}
.#{$wb-css-prefix}-copy:before {
	content: $wb-var-copy;
}
.#{$wb-css-prefix}-add-file:before {
	content: $wb-var-add-file;
}
.#{$wb-css-prefix}-print:before,
.#{$wb-css-prefix}-generateLabel:before, {
	content: $wb-var-print;
}
.#{$wb-css-prefix}-calendar:before {
	content: $wb-var-calendar;
}
.#{$wb-css-prefix}-time:before {
	content: $wb-var-time;
}
.#{$wb-css-prefix}-trash:before {
	content: $wb-var-trash;
}
.#{$wb-css-prefix}-plugin:before {
	content: $wb-var-plugin;
}
.#{$wb-css-prefix}-extension:before {
	content: $wb-var-extension;
}
.#{$wb-css-prefix}-memory:before {
	content: $wb-var-memory;
}
.#{$wb-css-prefix}-settings:before,
.#{$wb-css-prefix}-configs:before {
	content: $wb-var-settings;
}
.#{$wb-css-prefix}-scissor:before {
	content: $wb-var-scissor;
}
.#{$wb-css-prefix}-wrench:before {
	content: $wb-var-wrench;
}
.#{$wb-css-prefix}-hammer:before {
	content: $wb-var-hammer;
}
.#{$wb-css-prefix}-lock:before {
	content: $wb-var-lock;
}
.#{$wb-css-prefix}-unlock:before {
	content: $wb-var-unlock;
}
.#{$wb-css-prefix}-volume-low:before {
	content: $wb-var-volume-low;
}
.#{$wb-css-prefix}-volume-high:before {
	content: $wb-var-volume-high;
}
.#{$wb-css-prefix}-volume-off:before {
	content: $wb-var-volume-off;
}
.#{$wb-css-prefix}-pause:before {
	content: $wb-var-pause;
}
.#{$wb-css-prefix}-play:before {
	content: $wb-var-play;
}
.#{$wb-css-prefix}-stop:before {
	content: $wb-var-stop;
}
.#{$wb-css-prefix}-musical:before {
	content: $wb-var-musical;
}
.#{$wb-css-prefix}-random:before {
	content: $wb-var-random;
}
.#{$wb-css-prefix}-reload:before {
	content: $wb-var-reload;
}
.#{$wb-css-prefix}-loop:before {
	content: $wb-var-loop;
}
.#{$wb-css-prefix}-text:before {
	content: $wb-var-text;
}
.#{$wb-css-prefix}-bold:before {
	content: $wb-var-bold;
}
.#{$wb-css-prefix}-italic:before {
	content: $wb-var-italic;
}
.#{$wb-css-prefix}-underline:before {
	content: $wb-var-underline;
}
.#{$wb-css-prefix}-format-clear:before {
	content: $wb-var-format-clear;
}
.#{$wb-css-prefix}-text-type:before {
	content: $wb-var-text-type;
}
.#{$wb-css-prefix}-table:before {
	content: $wb-var-table;
}
.#{$wb-css-prefix}-attach-file:before {
	content: $wb-var-attach-file;
}
.#{$wb-css-prefix}-paperclip:before {
	content: $wb-var-paperclip;
}
.#{$wb-css-prefix}-link-intact:before {
	content: $wb-var-link-intact;
}
.#{$wb-css-prefix}-link:before {
	content: $wb-var-link;
}
.#{$wb-css-prefix}-link-broken:before {
	content: $wb-var-link-broken;
}
.#{$wb-css-prefix}-indent-increase:before {
	content: $wb-var-indent-increase;
}
.#{$wb-css-prefix}-indent-decrease:before {
	content: $wb-var-indent-decrease;
}
.#{$wb-css-prefix}-align-justify:before {
	content: $wb-var-align-justify;
}
.#{$wb-css-prefix}-align-left:before {
	content: $wb-var-align-left;
}
.#{$wb-css-prefix}-align-center:before {
	content: $wb-var-align-center;
}
.#{$wb-css-prefix}-align-right:before {
	content: $wb-var-align-right;
}
.#{$wb-css-prefix}-list-numbered:before {
	content: $wb-var-list-numbered;
}
.#{$wb-css-prefix}-list-bulleted:before {
	content: $wb-var-list-bulleted;
}
.#{$wb-css-prefix}-list:before {
	content: $wb-var-list;
}
.#{$wb-css-prefix}-emoticon:before {
	content: $wb-var-emoticon;
}
.#{$wb-css-prefix}-quote-right:before {
	content: $wb-var-quote-right;
}
.#{$wb-css-prefix}-code:before {
	content: $wb-var-code;
}
.#{$wb-css-prefix}-code-working:before {
	content: $wb-var-code-working;
}
.#{$wb-css-prefix}-code-unfold:before {
	content: $wb-var-code-unfold;
}
.#{$wb-css-prefix}-chevron-right:before {
	content: $wb-var-chevron-right;
}
.#{$wb-css-prefix}-chevron-left:before {
	content: $wb-var-chevron-left;
}
.#{$wb-css-prefix}-chevron-left-mini:before {
	content: $wb-var-chevron-left-mini;
}
.#{$wb-css-prefix}-chevron-right-mini:before {
	content: $wb-var-chevron-right-mini;
}
.#{$wb-css-prefix}-chevron-up:before {
	content: $wb-var-chevron-up;
}
.#{$wb-css-prefix}-chevron-down:before {
	content: $wb-var-chevron-down;
}
.#{$wb-css-prefix}-chevron-up-mini:before {
	content: $wb-var-chevron-up-mini;
}
.#{$wb-css-prefix}-chevron-down-mini:before {
	content: $wb-var-chevron-down-mini;
}
.#{$wb-css-prefix}-arrow-left:before {
	content: $wb-var-arrow-left;
}
.#{$wb-css-prefix}-arrow-right:before {
	content: $wb-var-arrow-right;
}
.#{$wb-css-prefix}-arrow-up:before {
	content: $wb-var-arrow-up;
}
.#{$wb-css-prefix}-arrow-down:before {
	content: $wb-var-arrow-down;
}
.#{$wb-css-prefix}-dropdown:before {
	content: $wb-var-dropdown;
}
.#{$wb-css-prefix}-dropup:before {
	content: $wb-var-dropup;
}
.#{$wb-css-prefix}-dropright:before {
	content: $wb-var-dropright;
}
.#{$wb-css-prefix}-dropleft:before {
	content: $wb-var-dropleft;
}
.#{$wb-css-prefix}-sort-vertical:before {
	content: $wb-var-sort-vertical;
}
.#{$wb-css-prefix}-triangle-left:before {
	content: $wb-var-triangle-left;
}
.#{$wb-css-prefix}-triangle-right:before {
	content: $wb-var-triangle-right;
}
.#{$wb-css-prefix}-triangle-down:before {
	content: $wb-var-triangle-down;
}
.#{$wb-css-prefix}-triangle-up:before {
	content: $wb-var-triangle-up;
}
.#{$wb-css-prefix}-check-circle:before {
	content: $wb-var-check-circle;
}
.#{$wb-css-prefix}-check:before {
	content: $wb-var-check;
}
.#{$wb-css-prefix}-check-mini:before {
	content: $wb-var-check-mini;
}
.#{$wb-css-prefix}-close:before {
	content: $wb-var-close;
}
.#{$wb-css-prefix}-close-mini:before {
	content: $wb-var-close-mini;
}
.#{$wb-css-prefix}-plus-circle:before {
	content: $wb-var-plus-circle;
}
.#{$wb-css-prefix}-plus:before {
	content: $wb-var-plus;
}
.#{$wb-css-prefix}-minus-circle:before {
	content: $wb-var-minus-circle;
}
.#{$wb-css-prefix}-minus:before {
	content: $wb-var-minus;
}
.#{$wb-css-prefix}-alert-circle:before {
	content: $wb-var-alert-circle;
}
.#{$wb-css-prefix}-alert:before {
	content: $wb-var-alert;
}
.#{$wb-css-prefix}-help-circle:before {
	content: $wb-var-help-circle;
}
.#{$wb-css-prefix}-help:before {
	content: $wb-var-help;
}
.#{$wb-css-prefix}-info-circle:before {
	content: $wb-var-info-circle;
}
.#{$wb-css-prefix}-info:before {
	content: $wb-var-info;
}
.#{$wb-css-prefix}-warning:before {
	content: $wb-var-warning;
}
.#{$wb-css-prefix}-heart:before {
	content: $wb-var-heart;
}
.#{$wb-css-prefix}-heart-outline:before {
	content: $wb-var-heart-outline;
}
.#{$wb-css-prefix}-star:before {
	content: $wb-var-star;
}
.#{$wb-css-prefix}-star-half:before {
	content: $wb-var-star-half;
}
.#{$wb-css-prefix}-star-outline:before {
	content: $wb-var-star-outline;
}
.#{$wb-css-prefix}-thumb-up:before {
	content: $wb-var-thumb-up;
}
.#{$wb-css-prefix}-thumb-down:before {
	content: $wb-var-thumb-down;
}
.#{$wb-css-prefix}-small-point:before {
	content: $wb-var-small-point;
}
.#{$wb-css-prefix}-medium-point:before {
	content: $wb-var-medium-point;
}
.#{$wb-css-prefix}-large-point:before {
	content: $wb-var-large-point;
}
