body {
  > div.ember-view {
    display: inherit;
    height: 100%;
  }

  > div#kiosk-printing-container {
    display: none;
  }
}
.dropdown {
  &.help {
    i {
      color: $color-blue-800;
    }
  }
}
.editable-empty,
.editable-empty:focus,
.editable-empty:hover {
  display: inline-block;
  position: relative;
  height: 1em;
  width: 50%;
  border-bottom: dashed 1px $color-indigo-600;
}
.dropdown-toggle::after {
  display: none;
}
.site-menubar-fold,
.site-menubar-unfold {
  height: 100%;
}
img.table_logo {
  width: 70px;
}
img.product-img {
  height: 70px;
  width: 70px;
}
img.invoices-config-logo {
  height: 100px;
  max-width: 360px;
}
.panel-actions {
  > div,
  > span {
    float: left;
    margin-right: 10px;
  }
  .label {
    padding: 0.8em 1em;
    border-radius: 0.3em;
    font-size: 12px;
    font-weight: 600;
  }
  .modal-body {
    .label {
      padding: 4px !important;
    }
  }
}
.form-inline {
  div.ember-view {
    display: inline-block;
  }
}
.panel.padder-v {
  padding: 3em;
  vertical-align: middle;
  text-align: center;
  font-weight: 700;

  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: $brand-primary-hover;
    color: $color-white;
  }
}
.panel-carrier {
  .panel.padder-v {
    padding: 3em;
    vertical-align: middle;
    text-align: center;
    font-weight: 700;
    background-color: #f5f5f5;
    border: 1px solid #dedede;
    display: block;
    width: 100%;
    height: 150px;

    &:hover {
      background-color: $brand-primary-hover;
      color: $color-white;
    }
  }
  .padder-v img {
    margin: 0 auto 10px;
    display: block;
    max-width: 100%;
    max-height: 80%;
  }
  .panel.padder-v.active {
    background-color: $brand-primary-active;
    color: $brand-default;
  }
}
table.table thead {
  .columns_sort_indicator,
  .columns_sort_indicator_asc,
  .columns_sort_indicator_asc_disabled,
  .columns_sort_indicator_desc,
  .columns_sort_indicator_desc_disabled {
    position: relative;
    padding-right: 16px;
    cursor: pointer;
  }
  .columns_sort_indicator:after,
  .columns_sort_indicator_asc:after,
  .columns_sort_indicator_desc:after {
    font-family: "Web Icons";
    opacity: 0.5;
    right: 5px;
    top: 30%;
    position: absolute;
    margin-top: 2px;
  }
  .columns_sort_indicator:after {
    content: "\f18c";
    opacity: 0.2;
  }
  .columns_sort_indicator_asc:after {
    content: "\f190";
  }
  .columns_sort_indicator_desc:after {
    content: "\f18f";
  }
  th.js-collection-title {
    vertical-align: middle;
  }
}
.form-horizontal {
  .control-span {
    div.edition-disabled {
      padding-top: 7px;
    }
  }
  .thing-of-label {
    padding-top: 7px;
  }
}
.widget {
  border-radius: 3px;
}
.widget-shadow {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.widget {
  position: relative;
  margin-bottom: 30px;
  background-color: #fff;
}
.widget-content button {
  cursor: auto;
  box-shadow: none;
}
table {
  thead {
    tr {
      th {
        div.input-group {
          min-width: 80px;
          max-width: 200px;
        }
        .tokenfield.form-control {
          max-width: 200px;
        }
      }
    }
  }
}
.masonry-grid-editable {
  .row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .col-lg-3,
  .col-sm-6 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .panel {
    width: 100%;
  }
}
tr.columns-search-field {
  th {
    vertical-align: top !important;
  }
}
.bootstrap-datetimepicker-widget {
  height: inherit !important;
  padding-top: 0;

  ul.list-unstyled {
    margin-bottom: 0;

    .collapse .timepicker .timepicker-picker {
      tr td {
        border-color: rgba(0, 0, 0, 0.1) !important;
      }

      tr:not(:first-child) td {
        border: none !important;
      }
    }
  }

  th.prev,
  th.next {
    padding: 1em 1.25em !important;
  }

  .datepicker table tr td,
  .timepicker table tr td {
    padding-right: 1em;
    text-align: center;
  }

  .datepicker-months,
  .datepicker-years {
    min-width: 20em;
  }

  .datepicker-decades {
    min-width: 25em;

    span.decade {
      font-size: 0.85em;
    }
  }

  .picker-switch {
    table {
      width: 100% !important;

      td {
        text-align: center !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        a {
          display: inline-block !important;
          width: 100% !important;
          padding: 0.75em 0 !important;
          cursor: pointer !important;
        }
      }
    }
  }
}
.datepicker-responsive {
  min-height: 450px;
}
.datepicker-group {
  .datepicker-addon-group {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
  }
  input,
  input:focus {
    top: inherit;
  }
  .input-group-addon {
    padding: 9px 10px;
  }
}
.field_with_errors label.floating-label {
  top: -0.8rem;
  font-size: 0.8rem !important;
  font-weight: 700 !important;
}
.sign-up {
  .form-group.form-material.cuke-login-email {
    float: left;
    width: 48%;
  }
}

div.cuke-login-email:nth-child(even) {
  margin-right: 1%;
}
div.cuke-login-email:nth-child(odd) {
  margin-left: 1%;
}
@media (min-width: 768px) {
  .site-menubar-fold .page,
  .site-menubar-fold .site-footer {
    margin-left: 0;
  }
  .site-menubar-unfold {
    .navbar-brand-logo {
      margin: 0 auto;
      display: block;
    }
    .site-navbar .navbar-header .navbar-brand {
      width: 100%;
    }
  }
}
@media (max-width: 480px) {
  .form-group.form-material.cuke-login-email {
    float: left;
    width: 100%;
  }
  div.cuke-login-email:nth-child(even) {
    margin-right: 0%;
  }
  div.cuke-login-email:nth-child(odd) {
    margin-left: 0%;
  }
}
