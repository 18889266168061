// Name:      Mapped Product Recopmpute
//
// Component:     `mapped-product-recompute`
//
// ========================================================================

// Variables
// ========================================================================

// Component: mapped-product-recompute
// ========================================================================

a.mapped-product-recompute {
  transition: color .4s;

  &.error {
    color: $color-red-600;
  }

  &.success {
    color: $color-green-600;
  }

  &.loading {
    i.icon {
      animation-name: animation-mapped-product-recompute;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
    }
  }
}

@keyframes animation-mapped-product-recompute {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
