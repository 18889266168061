.style-packageLabel-order-block {
  background-color: #EEE;
  padding: 0 16px;
}

.style-package-label-display-inner {
  padding-top: 7px;
}

.style-package-label-display {
  padding-top: 15px;
}
