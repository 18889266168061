@mixin site-page-unfold {
  .page, .site-footer {
    margin-left: $site-menubar-unfold-width;
  }

  &.page-aside-fixed {
    .page-aside {
      left: $site-menubar-unfold-width;
    }

    .site-footer {
      margin-left: ($page-aside-width + $site-menubar-unfold-width);
    }
  }
};

@mixin site-page-md-unfold {
  &.page-aside-fixed {
    .site-footer {
      margin-left: ($page-aside-md-width + $site-menubar-unfold-width);
    }
  }
};

@mixin site-navbar-unfold {
  .site-navbar {
    @include media-breakpoint-up(md) {
      .navbar-header {
        width: $site-menubar-unfold-width;
      }
      .navbar-container {
        margin-left: $site-menubar-unfold-width;
      }
    }
  }
};

@mixin site-menu-unfold {
  .site-menu {
    &-category {
      transition: all .25s, font .1s .15s, color .1s .15s;

      font-size: 15px;
      padding: 0 24px;
      color: $site-menu-category-color;
      line-height: $site-menu-top-height;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .site-menu-item {
      > a {
        .site-menu-title {
          font-size: $site-menu-top-font-size;//16px
        }
      }
    }

    &-icon {
      margin-right: $site-menu-top-font-size;//16px
    }

    &-title {
      display: inline-block;
      max-width: 160px;

      &:first-child {

        margin-left: (2 * $site-menu-top-font-size);
      }
    }

    .site-menu-sub {
      display: none;
    }

    &-item {
      transition: transform .1s, all .25s, border 0;
      line-height: $site-menu-sub-height;

      &.open {
        > a {
          .site-menu-arrow {
            @include transform(rotate(90deg));
          }
        }

        .site-menu-sub {
          display: block;
        }
      }

      &:not(.open) {
        > a {
          .site-menu-arrow {
            @include transform(none);
          }
        }

        .site-menu-sub {
          display: none;
        }
      }
    }

    .site-menu-item {
      > a {
        padding: 0 30px;
        line-height: $site-menu-top-height;
      }
    }
  }
};

@mixin site-menubar-unfold {
  &.site-menubar-native {
    .site-menubar-body {
      overflow-y: scroll;
    }
  }

  .site-menubar {
    width: $site-menubar-unfold-width;
  }
};

@mixin site-gridmenu-unfold {
  .site-gridmenu {
    width: $site-menubar-unfold-width;
  }
};

@mixin site-menubar-footer-unfold {
  .site-menubar-footer {
    width: $site-menubar-unfold-width;
  }
};

@mixin site-menubar-toggle-unfold {
  [data-toggle="menubar"] {
    .hamburger-arrow-left {
      @include hamburger-arrow-left();
    }
  }
};

.css-menubar {
  @include media-breakpoint-down(sm) {
    // @extend %site-navbar-unfold;
    // @extend %site-menu-unfold;
    // @extend %site-menubar-unfold;
    // @extend %site-gridmenu-unfold;
    // @extend %site-menubar-footer-unfold;
    // @extend %site-menubar-toggle-unfold;

    .site-menu-item {
      &:hover {
        .site-menu-sub {
          display: block;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    @include site-page-unfold;

    @include site-navbar-unfold;
    @include site-menu-unfold;
    @include site-menubar-unfold;
    @include site-gridmenu-unfold;
    @include site-menubar-footer-unfold;
    @include site-menubar-toggle-unfold;

    .site-menu-item {
      &:hover {
        .site-menu-sub {
          display: block;
        }
      }
    }
  }
}

// @include media-breakpoint-up(xl) {
//   .css-menubar {
//     @include site-page-unfold;
//   }
// }

//.js-menubar {
  .site-menubar-unfold {
    @include site-page-unfold;

    @include site-navbar-unfold;
    @include site-menu-unfold;
    @include site-menubar-unfold;
    @include site-gridmenu-unfold;
    @include site-menubar-footer-unfold;
    @include site-menubar-toggle-unfold;
  }
//}

@include media-breakpoint-between(md,lg) {
  .site-menubar-unfold {
    @include site-page-md-unfold;
  }
}

@include media-breakpoint-down(sm) {
  //.js-menubar {
    .site-menubar-unfold {
      .page, .site-footer {
        margin-left: auto;
      }
    }

    .site-menubar-open {
      .page, .site-footer {
        -ms-transform: translate($site-menubar-unfold-width, 0);
        @include transform(translate3d($site-menubar-unfold-width, 0, 0));
      }
    }

    .site-menubar-changing {
      overflow: hidden;

      .page, .site-footer {
        transition: transform .25s;
      }
    }

    .site-gridmenu {
      width: 100% !important;
      background: rgba($site-gridmenu-bg, (90/100));
    }
  //}

  .site-menubar {
    .site-menubar-hide &,
    .site-menubar-open & {
      transition: transform .25s, top .35s, height .35s;
    }

    .site-menubar-open & {
      -ms-transform: translate(0, 0);
      @include transform(translate3d(0, 0, 0));
    }
  }
}
