// Variables
// --------------------------
$wb-font-file:        "web-icons";
$wb-font-family:      "Web Icons";
$wb-css-prefix:       wb;
$wb-version:          "0.2.3";

$wb-var-dashboard: "\f101";
$wb-var-inbox: "\f102";
$wb-var-cloud: "\f103";
$wb-var-bell: "\f104";
$wb-var-book: "\f105";
$wb-var-bookmark: "\f106";
$wb-var-tag: "\f107";
$wb-var-library: "\f108";
$wb-var-share: "\f109";
$wb-var-reply: "\f10a";
$wb-var-refresh: "\f10b";
$wb-var-move: "\f10c";
$wb-var-chat: "\f10d";
$wb-var-chat-working: "\f10e";
$wb-var-chat-text: "\f10f";
$wb-var-chat-group: "\f110";
$wb-var-envelope: "\f111";
$wb-var-envelope-open: "\f112";
$wb-var-user: "\f113";
$wb-var-user-circle: "\f114";
$wb-var-users: "\f115";
$wb-var-user-add: "\f116";
$wb-var-grid-9: "\f117";
$wb-var-grid-4: "\f118";
$wb-var-menu: "\f119";
$wb-var-layout: "\f11a";
$wb-var-fullscreen: "\f11b";
$wb-var-fullscreen-exit: "\f11c";
$wb-var-expand: "\f11d";
$wb-var-contract: "\f11e";
$wb-var-arrow-expand: "\f11f";
$wb-var-arrow-shrink: "\f120";
$wb-var-desktop: "\f121";
$wb-var-mobile: "\f122";
$wb-var-signal: "\f123";
$wb-var-power: "\f124";
$wb-var-more-horizontal: "\f125";
$wb-var-more-vertical: "\f126";
$wb-var-globe: "\f127";
$wb-var-map: "\f128";
$wb-var-flag: "\f129";
$wb-var-pie-chart: "\f12a";
$wb-var-stats-bars: "\f12b";
$wb-var-pluse: "\f12c";
$wb-var-home: "\f12d";
$wb-var-shopping-cart: "\f12e";
$wb-var-payment: "\f12f";
$wb-var-briefcase: "\f130";
$wb-var-search: "\f131";
$wb-var-zoom-in: "\f132";
$wb-var-zoom-out: "\f133";
$wb-var-download: "\f134";
$wb-var-upload: "\f135";
$wb-var-sort-asc: "\f136";
$wb-var-sort-des: "\f137";
$wb-var-graph-up: "\f138";
$wb-var-graph-down: "\f139";
$wb-var-replay: "\f13a";
$wb-var-edit: "\f13b";
$wb-var-pencil: "\f13c";
$wb-var-rubber: "\f13d";
$wb-var-crop: "\f13e";
$wb-var-eye: "\f13f";
$wb-var-eye-close: "\f140";
$wb-var-image: "\f141";
$wb-var-gallery: "\f142";
$wb-var-video: "\f143";
$wb-var-camera: "\f144";
$wb-var-folder: "\f145";
$wb-var-clipboard: "\f146";
$wb-var-order: "\f147";
$wb-var-file: "\f148";
$wb-var-copy: "\f149";
$wb-var-add-file: "\f14a";
$wb-var-print: "\f14b";
$wb-var-calendar: "\f14c";
$wb-var-time: "\f14d";
$wb-var-trash: "\f14e";
$wb-var-plugin: "\f14f";
$wb-var-extension: "\f150";
$wb-var-memory: "\f151";
$wb-var-settings: "\f152";
$wb-var-scissor: "\f153";
$wb-var-wrench: "\f154";
$wb-var-hammer: "\f155";
$wb-var-lock: "\f156";
$wb-var-unlock: "\f157";
$wb-var-volume-low: "\f158";
$wb-var-volume-high: "\f159";
$wb-var-volume-off: "\f15a";
$wb-var-pause: "\f15b";
$wb-var-play: "\f15c";
$wb-var-stop: "\f15d";
$wb-var-musical: "\f15e";
$wb-var-random: "\f15f";
$wb-var-reload: "\f160";
$wb-var-loop: "\f161";
$wb-var-text: "\f162";
$wb-var-bold: "\f163";
$wb-var-italic: "\f164";
$wb-var-underline: "\f165";
$wb-var-format-clear: "\f166";
$wb-var-text-type: "\f167";
$wb-var-table: "\f168";
$wb-var-attach-file: "\f169";
$wb-var-paperclip: "\f16a";
$wb-var-link-intact: "\f16b";
$wb-var-link: "\f16c";
$wb-var-link-broken: "\f16d";
$wb-var-indent-increase: "\f16e";
$wb-var-indent-decrease: "\f16f";
$wb-var-align-justify: "\f170";
$wb-var-align-left: "\f171";
$wb-var-align-center: "\f172";
$wb-var-align-right: "\f173";
$wb-var-list-numbered: "\f174";
$wb-var-list-bulleted: "\f175";
$wb-var-list: "\f176";
$wb-var-emoticon: "\f177";
$wb-var-quote-right: "\f178";
$wb-var-code: "\f179";
$wb-var-code-working: "\f17a";
$wb-var-code-unfold: "\f17b";
$wb-var-chevron-right: "\f17c";
$wb-var-chevron-left: "\f17d";
$wb-var-chevron-left-mini: "\f17e";
$wb-var-chevron-right-mini: "\f17f";
$wb-var-chevron-up: "\f180";
$wb-var-chevron-down: "\f181";
$wb-var-chevron-up-mini: "\f182";
$wb-var-chevron-down-mini: "\f183";
$wb-var-arrow-left: "\f184";
$wb-var-arrow-right: "\f185";
$wb-var-arrow-up: "\f186";
$wb-var-arrow-down: "\f187";
$wb-var-dropdown: "\f188";
$wb-var-dropup: "\f189";
$wb-var-dropright: "\f18a";
$wb-var-dropleft: "\f18b";
$wb-var-sort-vertical: "\f18c";
$wb-var-triangle-left: "\f18d";
$wb-var-triangle-right: "\f18e";
$wb-var-triangle-down: "\f18f";
$wb-var-triangle-up: "\f190";
$wb-var-check-circle: "\f191";
$wb-var-check: "\f192";
$wb-var-check-mini: "\f193";
$wb-var-close: "\f194";
$wb-var-close-mini: "\f195";
$wb-var-plus-circle: "\f196";
$wb-var-plus: "\f197";
$wb-var-minus-circle: "\f198";
$wb-var-minus: "\f199";
$wb-var-alert-circle: "\f19a";
$wb-var-alert: "\f19b";
$wb-var-help-circle: "\f19c";
$wb-var-help: "\f19d";
$wb-var-info-circle: "\f19e";
$wb-var-info: "\f19f";
$wb-var-warning: "\f1a0";
$wb-var-heart: "\f1a1";
$wb-var-heart-outline: "\f1a2";
$wb-var-star: "\f1a3";
$wb-var-star-half: "\f1a4";
$wb-var-star-outline: "\f1a5";
$wb-var-thumb-up: "\f1a6";
$wb-var-thumb-down: "\f1a7";
$wb-var-small-point: "\f1a8";
$wb-var-medium-point: "\f1a9";
$wb-var-large-point: "\f1aa";
