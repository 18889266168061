//
// Grid system
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  max-width: 100%;
}

@include media-breakpoint-up(xxl) {
  .container {
    width: map-get($container-max-widths,xxl);
  }
}


// Gird
// ========================================================================
.row.no-space {
  margin-right: 0;
  margin-left: 0;

  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.row-lg {
  margin-left:  ($grid-lg-gutter-width / -2);
  margin-right: ($grid-lg-gutter-width / -2);
}

@mixin make-lg-grid-columns($columns: $grid-columns, $gutter: $grid-lg-gutter-width, $breakpoints: $grid-breakpoints){
  %grid-column {
    // Inner gutter via padding
    padding-left: ($grid-lg-gutter-width / 2);
    padding-right: ($grid-lg-gutter-width / 2);
  }

  @each $breakpoint in map-keys($breakpoints) {
    @for $i from 1 through $columns {
      .row-lg > .col-#{$breakpoint}-#{$i} {
        @extend %grid-column;
      }
    }
  }
}
@include make-lg-grid-columns();