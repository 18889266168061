.site-menu {
  font-size: 14px;
  overflow-y: hidden;
  padding-bottom: 20px;

  &-item > a {
    white-space: nowrap;
    cursor: pointer;
  }

  &-icon {
    display: inline-block;

    @include icon-fixed-width();
  }

  &-title {
    vertical-align: middle;

    @include text-truncate();
  }

  &-arrow {
    font-family: '#{$wb-font-family}';

    transition: transform 0.15s;

    @include make-icon();
    &:before {
      content: '#{$wb-var-chevron-right-mini}';
    }
  }

  &-arrow, &-badge, &-label {
    position: absolute;
    right: $site-menu-padding-horizontal;
    display: inline-block;
    vertical-align: middle;
  }

  .badge, .label {
    padding: 2px 5px 3px;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
  }

  &-item {
    position: relative;
    white-space: nowrap;

    a {
      display: block;
      color: $site-menubar-color;
    }

    &.is-hidden {
      opacity: 0;
    }

    &.is-shown {
      opacity: 1;
    }
  }

  .site-menu-item {
    position: relative;
    padding: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: .2em;
      height: 100%;
      background-color: currentColor;
      border-top-right-radius: .1em;
      border-bottom-right-radius: .1em;
      opacity: .5;
      transform: translateX(-.2em);
      transition: transform .3s, opacity .3s, background-color .3s;;
    }

    > a {
      transition: background-color .2s, color .2s;
    }

    &.site-menu-item-sub {
      max-height: 0em;
      overflow: hidden;
      background-color: rgba(0, 0, 0, .1);
      transition: max-height .4s;
      transition-delay: .3s;
      will-change: max-height;
      pointer-events: none;

      &.visible {
        max-height: 3em;
        pointer-events: auto;
      }

      a {
        padding-left: calc(30px + 1em) !important;
      }
    }

    &.open {
      color: $site-menu-top-open-color;

      &:before {
        transform: translateX(0);
        opacity: 1;
      }

      > a {
        color: $site-menu-top-open-color;
      }
    }

    &.hover,
    &:hover {
      // color: $site-menu-top-hover-color;
      > a {
        color: $site-menu-top-hover-color;
        background-color: $site-menu-top-hover-bg;
      }
    }

    &.active {
      background: $site-menu-top-active-bg;

      border-top: 1px solid $site-menu-top-active-border-color;
      border-bottom: 1px solid $site-menu-top-active-border-color;

      > a {
        color: $site-menu-top-active-color;
        background: $site-menu-top-active-head-bg;
      }

      &.hover {
        > a {
          background: $site-menu-top-active-head-bg;
        }
      }
    }
  }

  .site-menu-sub {
    padding: 5px 0;
    margin: 0;
    font-size: 14px;
    background: $site-menu-sub-bg;

    .site-menu-item {
      line-height: $site-menu-sub-height;
      color: $site-menu-sub-color;
      background: transparent;

      &.has-sub {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        // &.open {
        //   border-top-color: $site-menu-has-sub-border-color;
        //   border-bottom-color: $site-menu-has-sub-border-color;
        // }
      }

      > a {
        padding: 0 $site-menu-padding-horizontal;
      }

      &.open {
        background: $site-menu-sub-open-bg;

        > a {
          color: $site-menu-sub-open-color;
        }

        &.hover {
          > a {
            background-color: transparent;
          }
        }
      }

      &.hover, &:hover {
        > a {
          color: $site-menu-sub-hover-color;
          background-color: $site-menu-sub-hover-bg;
        }
      }

      &.active {
        background: $site-menu-sub-active-bg;

        > a {
          color: $site-menu-sub-active-color;
        }

        &.hover {
          > a {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.site-menubar-light {
  .site-menu {
    &-item {
      a {
        color: $site-menubar-light-color;
      }
      &.hover, &:hover {
        > a {
          background: $site-menu-light-top-open-head-bg;
        }
      }
    }
    .site-menu-item {
      &.open {
        background: $site-menu-light-top-open-bg;
        > a {
          color: $site-menu-light-sub-open-color;
        }
      }

      &.hover, &:hover {
        background-color: $site-menu-light-top-hover-bg;
        > a {
          color: $site-menu-light-top-hover-color;
        }
      }

      &.active {
        background: $site-menu-light-top-active-bg;
        > a {
          color: $site-menu-light-top-active-color;
        }
      }
    }
    .site-menu-sub {
      .site-menu-item {
        &.open {
          background: $site-menu-light-sub-open-bg;
          > a {
            color: $site-menu-light-sub-open-color;
          }
        }

        &.hover, &:hover {
          background-color: $site-menu-light-sub-active-bg;
          > a {
            color: $site-menu-light-sub-hover-color;
          }
          &.open {
            background-color: $site-menu-light-sub-open-bg;
          }
        }

        &.active {
          background: $site-menu-light-sub-active-bg;
          > a {
            color: $site-menu-light-sub-active-color;
          }
        }
      }
    }
  }
}
