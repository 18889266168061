

//== Site Navbar
//
//##
$brand-font-family:        $headings-font-family;
$site-navbar-height:       remToPx($navbar-height);
$site-navbar-color:        $gray-dark;
$site-navbar-hover-color:  $gray-dark;
$site-navbar-brand-bg:     $gray-darkest;

$site-navbar-header-bg:    $color-blue-600;
$site-navbar-header-color: $inverse;

$site-navbar-brand-color:  $inverse;

//== Site Menubar
//
//##
$site-menubar-bg:                   $gray-base;
$site-menubar-unfold-width:         260px;
$site-menubar-fold-width:           90px;

$site-menubar-padding-horizontal:   30px;

$site-menubar-color:                rgba($gray, (90/100));
$site-menubar-font-family:          $headings-font-family;
$site-menubar-shadow:               0 2px 4px rgba(0, 0, 0, 0.08);

$site-menubar-section-title-color:  $gray-dark;

$site-menubar-footer-height:        76px;
$site-menubar-footer-color:         $gray-dark;
$site-menubar-footer-link-bg:       rgb(33, 41, 46);
$site-menubar-footer-link-hover-bg: rgb(30, 36, 39);

//== Site Menu
//
//##
$site-menu-top-weight:              $font-weight-bold;
$site-menu-top-hover-color:         rgba($inverse, .8);
$site-menu-top-hover-bg:            rgba(255, 255, 255, .02);

// $site-menu-top-active-head-bg:     $color-purple;
// $site-menu-top-active-head-bg:     rgba($color-primary-600, (10/100));
$site-menu-top-active-head-bg:      transparent;
$site-menu-top-active-bg:           darken($site-menubar-bg, 1%) !default;
$site-menu-top-active-border-color: rgba(0,0,0,.04);
$site-menu-top-active-color:        $inverse;

$site-menu-top-open-head-bg:        transparent;
//$site-menu-top-open-bg:             transparent;
$site-menu-top-open-bg:             $site-menu-top-active-bg;
$site-menu-top-open-color:          $site-menu-top-active-color;
$site-menu-top-height:              38px;
$site-menu-top-font-size:           14px;

$site-menu-sub-bg:                 transparent;
$site-menu-sub-color:              $site-menubar-color;
$site-menu-sub-hover-bg:           rgba(255, 255, 255, .02);
$site-menu-sub-hover-color:        $site-menu-top-hover-color;
$site-menu-sub-active-bg:          rgba(0, 0, 0, .06);
$site-menu-sub-active-color:       $inverse;
$site-menu-sub-open-bg:            $site-menu-sub-active-bg;
$site-menu-sub-open-color:         $site-menu-sub-active-color;
$site-menu-sub-height:             34px;

$site-menu-category-color:         $gray-dark;

$site-menu-has-sub-border-color:   rgba(0, 0, 0, .06);
$site-menu-padding-horizontal:     $site-menubar-padding-horizontal;


//== Site Menubar Fold
//
//##
$site-menubar-fold-nav-height: 60px;
$site-menubar-fold-sub-bg:     $site-menu-top-active-bg;
$site-menubar-fold-sub-width:  240px;
$site-menubar-fold-divider-bg: darken($site-menubar-bg, 1%);

//Light
$site-menubar-light-fold-sub-bg:     rgb(243, 248, 251);
$site-menubar-light-fold-divider-bg: rgba($color-blue-800, (5/100));
$site-menubar-light-fold-box-shadow: 2px 1px 2px rgba(0,0,0,.08);

//== Site Menubar Fold Alt
//
//##
$site-menubar-fold-top-active-bg: $gray-darkest;

//Light
$site-menubar-light-fold-top-active-bg: $color-blue-100;
$site-menubar-light-fold-alt-box-shadow: none;



//== Site Menubar light
//
//##
$site-menubar-light-color:                rgba($gray-dark, (90/100));
$site-menubar-light-bg:                   $inverse;
$site-menubar-light-shadow:               $site-menubar-shadow;
$site-menubar-light-footer-link-bg:       $gray-lighter;
$site-menubar-light-footer-link-hover-bg: darken($gray-lighter, 5%);

//== Site Menu light
//
//##
$site-menu-light-top-open-head-bg:      transparent;
$site-menu-light-top-hover-bg:          rgba($color-primary-800, (5/100));
$site-menu-light-top-open-bg:           $site-menu-light-top-hover-bg;
$site-menu-light-top-active-bg:         $site-menu-light-top-hover-bg;
$site-menu-light-sub-active-bg:         rgba($color-primary-800, (3/100));
$site-menu-light-sub-open-bg:           rgba($color-primary-800, (3/100));
$site-menu-light-has-sub-border-color:  $site-menu-light-top-hover-bg;

$site-menu-light-top-hover-color:       $color-primary-600;
$site-menu-light-top-active-color:      $site-menu-light-top-hover-color;
$site-menu-light-sub-active-color:      $site-menu-light-top-hover-color;
$site-menu-light-sub-open-color:        $site-menu-light-sub-active-color;
$site-menu-light-sub-hover-color:       $site-menu-light-top-hover-color;


//== Site Grid Menu
//
//##
$site-gridmenu-color:          $gray;
$site-gridmenu-hover-color:    $inverse;
$site-gridmenu-bg:             $gray-base;
$site-gridmenu-hover-bg:       rgba($inverse, (2/100));

$site-gridmenu-toggle-size:    $font-size-base;

//== Site Sidebar
//
//##
$site-sidebar-width: 300px;
$site-sidebar-z-index: ($zindex-navbar + 10);
$site-sidebar-nav-height: 90px;
$site-sidebar-padding-horizontal: 30px;

//== Page
//
//##
$page-bg:                 #eeeeee;

$page-vertical-padding:   30px;
$page-horizontal-padding: 30px;

//== Page Header
//
//##
$page-header-bg:                        transparent;
$page-header-bordered-bg:               $inverse;
$page-header-vertical-padding:          30px;
$page-header-bordered-vertical-padding: 20px;


//== Page Aside
//
//##
$page-aside-padding-horizontal: 30px;
$page-aside-width:              260px;
$page-aside-md-width:           220px;
$page-aside-bg:                 $inverse;

//== Site Footer
//
//##
$site-footer-vertical-padding: 10px;
$site-footer-bg:               rgba(0,0,0,.02);
$site-footer-divider-color:    $gray-lighter;
$site-footer-height:           44px;
$site-footer-xs-height: 		66px;

//== Layout Boxed
//
//##
$layout-boxed-width: 1320px;
$layout-boxed-bg:    $gray-lighter;

//== Panels
//
///##
$panel-bg:                       #fff !default;
$panel-padding-horizontal:       30px !default;
$panel-body-padding-vertical:    30px !default;
$panel-body-padding:             $panel-body-padding-vertical $panel-padding-horizontal !default;
$panel-heading-padding-vertical: 20px !default;
$panel-heading-padding:          $panel-heading-padding-vertical $panel-padding-horizontal !default;
$panel-footer-padding-vertical:  15px !default;
$panel-footer-padding:           0 $panel-padding-horizontal $panel-footer-padding-vertical !default;
$panel-border-radius:            $border-radius-large !default;
$panel-border:                   $border-color-base !default;

//** Border color for elements within panels
$panel-inner-border:          $border-color-base !default;
$panel-footer-bg:             transparent !default;

$panel-default-text:          $text-color !default;
$panel-default-border:        $border-color-base !default;
$panel-default-heading-bg:    $brand-default !default;

$panel-primary-text:          #fff !default;
$panel-primary-border:        $brand-primary !default;
$panel-primary-heading-bg:    $brand-primary !default;

$panel-success-text:          $state-success-text !default;
$panel-success-border:        $state-success-border !default;
$panel-success-heading-bg:    $state-success-bg !default;

$panel-info-text:             $state-info-text !default;
$panel-info-border:           $state-info-border !default;
$panel-info-heading-bg:       $state-info-bg !default;

$panel-warning-text:          $state-warning-text !default;
$panel-warning-border:        $state-warning-border !default;
$panel-warning-heading-bg:    $state-warning-bg !default;

$panel-danger-text:           $state-danger-text !default;
$panel-danger-border:         $state-danger-border !default;
$panel-danger-heading-bg:     $state-danger-bg !default;

$panel-shadow:                none !default;
