// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: inline-block;

  @include hover-focus {
    text-decoration: none;
  }

  // Disabled state lightens text and removes hover/tab effects
  &.disabled, &[disabled] {
    color: $nav-disabled-link-color;

    @include plain-hover-focus {
      color: $nav-disabled-link-hover-color;
      cursor: $cursor-disabled;
      background-color: transparent;
    }
  }
}


// Nav inline

.nav-inline {
  .nav-item {
    display: inline-block;
  }

  .nav-item + .nav-item,
  .nav-link + .nav-link {
    margin-left: 1rem;
  }
}


//
// Tabs
//

.nav-tabs {
  padding: 0 2em;
  border-bottom: 1px solid $nav-tabs-border-color;
  @include clearfix();

  .nav-item {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -1px;

    + .nav-item {
      margin-left: .2rem;
    }
  }

  .nav-link {
    display: block;
    padding: $nav-link-padding;
    border: $nav-tabs-link-border-width solid transparent;
    @include border-radius($border-radius $border-radius 0 0);

    @include hover-focus {
      border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
    }

    &.disabled {
      @include plain-hover-focus {
        color: $nav-disabled-link-color;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  .nav-link.active,
  .nav-item.open .nav-link {
    @include plain-hover-focus {
      color: $nav-tabs-active-link-hover-color;
      background-color: $nav-tabs-active-link-hover-bg;
      border-color: $nav-tabs-active-link-hover-border-color $nav-tabs-active-link-hover-border-color transparent;
    }
  }

  > li {
    position: relative;
    border: none;

    text-transform: uppercase;
    font-size: .9em;
    font-weight: bold;

    @include no-select;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: .3em;

      background-color: $brand-primary;
      opacity: 0;
      transform-origin: center;
      transform: scaleX(0);

      transition: opacity .3s, transform .3s;
    }

    &.active {
      border: none;

      &:after {
        opacity: 1;
        transform: scaleX(1);
      }

      > a, > a:hover {
        border: none;
      }
    }

    &.add > a:before {
      content: '\f067';
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;
      margin-right: .5em;
      font-family: "FontAwesome";
    }

    > a, > a:hover {
      border: none;
      transition: background-color .3s;
    }

    > a:hover {
      background-color: transparentize($color-black, .93);
    }
  }
}


//
// Pills
//

.nav-pills {
  @include clearfix();

  .nav-item {
    float: left;

    + .nav-item {
      margin-left: .2rem;
    }
  }

  .nav-link {
    display: block;
    padding: $nav-link-padding;
    @include border-radius($nav-pills-border-radius);
  }

  .nav-link.active,
  .nav-item.open .nav-link {
    @include plain-hover-focus {
      color: $component-active-color;
      cursor: default;
      background-color: $component-active-bg;
    }
  }
}

.nav-stacked {
  .nav-item {
    display: block;
    float: none;

    + .nav-item {
      margin-top: .2rem;
      margin-left: 0;
    }
  }
}


//
// Tabbable tabs
//

// Hide tabbable panes to start, show them when `.active`
.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}


//
// Dropdowns
//

.nav-tabs .dropdown-menu {
  // Make dropdown border overlap tab border
  margin-top: -1px;
  // Remove the top rounded corners here since there is a hard edge above the menu
  @include border-top-radius(0);
}
