.style-label-pricing-table {
  margin-left: 100px;
  margin-right: 100px;
}

.style-label-pricing-table th {
  font-weight: bold;
}

.style-label-pricing-table .style-pricing-table-header-row {
  font-weight: bold;
  color: #EEE;
  background-color: #23a8bf;
}

.style-label-pricing-table .style-pricing-table-row:nth-child(even){
  background-color: #DDD;
}
