// Name:            Jumbotron
//
// Component:       `jumbotron`
//
// ========================================================================

// Variables
// ========================================================================

$jumbotron-radius: $border-radius !default;

// Component: Jumbotron
// ========================================================================

.jumbotron {
  padding: 0;
  border-radius: $jumbotron-radius;

  & > &-photo img {
    width: 100%;

    @include border-top-radius($jumbotron-radius);
  }

  &-contents {
    padding: $jumbotron-padding;
  }

  .carousel,
  .carousel-inner,
  .carousel-inner > .carousel-item.active img {
    @include border-top-radius($jumbotron-radius);
  }

  .carousel-inner > .carousel-item > a > img,
  .carousel-inner > .carousel-item > img {
    width: 100%;
  }

  h1, .h1 {
    font-size: 28px;
  }

  h2, .h2 {
    font-size: 24px;
  }

  @include media-breakpoint-up(md) {
    &, .container & {
      padding: 0;
    }

    h1,
    .h1 {
      font-size: 28px;
    }
  }
}
