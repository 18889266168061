// Name:            Pagination
//
// Component:       `pagination`
//
// Modifiers:       `pagination-gap`
//                  `pagination-no-border`
//                  `pagination-lg`
//                  `pagination-sm`
//
// Markup:
//
// <!-- pagination -->
// <ul class="pagination">
//     <li class="previous"><a href=""></a></li>
//     <li><a href="#">1</a></li>
//     <li><a href="#">2</a></li>
//     <li class="next"><a href=""></a></li>
// </ul>
//
// ========================================================================

// Variables
// ========================================================================
$pagination-link-padding:                   9px 15px !default;
$pagination-gap-hover-border:               $brand-primary !default;

$pagination-lg-font-size:                   16px !default;
$pagination-lg-padding-horizontal:          17px !default;

$pagination-small-font-size:                $font-size-base !default;
$pagination-small-padding-horizontal:       11px !default;

// Component: Pagination
// ========================================================================

.pagination {
  .page-item {
    // Link
    > .page-link,
    > span {
      padding: $pagination-link-padding;
      color: $color-black-300;
      transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out;
      @include no-select;

      &:hover,
      &:focus {
        transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out;
        text-decoration: none;        // add from---v3
      }
    }

    &.active > span {
      color: $color-white;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-bg;
    }

    &.success > * {
      color: $color-white;
      background-color: $brand-success;
      border-color: darken($brand-success, 5%);

      &:hover, &:focus {
        background-color: $brand-success-hover;
        border-color: darken($brand-success-hover, 5%);
      }

      &:active {
        background-color: $brand-success-active;
        border-color: darken($brand-success-active, 5%);
      }
    }

    .icon {
      margin-top: -1px;
    }
  }


  > .nav-link.active, .nav-link.active:hover {
    z-index: 3;
    color: $pagination-active-color;
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border;
    cursor: default;
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > .page-link,
    > .page-link:hover,
    > .page-link:focus {
      color: $pagination-disabled-color;
      cursor: $cursor-disabled;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border;
    }
  }
}

// Modifier: `pagination-gap`
// -------------------------

.pagination-gap {
  > .page-item {
    &.disabled .page-link:hover{
      border-color: $pagination-disabled-border;
    }

    > .page-link {
      margin: 0 5px;
      border-radius: 5px;

      &:hover {
        background-color: transparent;
        border-color: $pagination-gap-hover-border;
      }
    }

    &:first-child,
    &:last-child {
      > .page-link {
        border-radius: 5px;
      }
    }

    &.active {
      > .page-link {
        background-color: $pagination-active-bg;
      }
    }
  }
}


// Modifier: `pagination-no-border`
// -------------------------

.pagination-no-border {
  > .page-item {
    > .page-link {
      border: none;
    }
  }
}

// Size Modifier
// ========================================================================

// .pagination-lg {
//   @include pagination-size($padding-large-vertical, $pagination-lg-padding-horizontal, $pagination-lg-font-size, $line-height-lg, $border-radius-large);
// }

// .pagination-sm {
//   @include pagination-size($padding-small-vertical, $pagination-small-padding-horizontal, $pagination-small-font-size, $line-height-sm, $border-radius-small);
// }
