$color-facebook:             #3B5998 !default;
$color-twitter:              #55ACEE !default;
$color-google-plus:          #DD4B39 !default;
$color-linkedin:             #0976B4 !default;
$color-flickr:               #FF0084 !default;
$color-tumblr:               #35465C !default;
$color-xing:                 #024b4d !default;
$color-github:               #4183c4 !default;
$color-html5:                #E44F26 !default;
$color-openid:               #f67d28 !default;
$color-stack-overflow:       #f86c01 !default;
$color-css3:                 #1572B6 !default;
$color-youtube:              #B31217 !default;
$color-dribbble:             #C32361 !default;
$color-instagram:            #3F729B !default;
$color-pinterest:            #CC2127 !default;
$color-vk:                   #3d5a7d !default;
$color-yahoo:                #350178 !default;
$color-behance:              #1769FF !default;
$color-dropbox:              #007EE5 !default;
$color-reddit:               #FF4500 !default;
$color-spotify:              #7AB800 !default;
$color-vine:                 #00B488 !default;
$color-foursquare:           #0cbadf !default;
$color-vimeo:                #1AB7EA !default;
$color-skype:                #77bcfd !default;
$color-evernote:             #46bf8c !default;