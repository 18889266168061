.page-login {
  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background: $color-blue-600;
    /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzYyYThlYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxNTcxYjEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
    background-image: -webkit-linear-gradient(top, $color-blue-600 0%, $color-blue-800 100%);
    background-image: -o-linear-gradient(top, $color-blue-600 0%, $color-blue-800 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from($color-blue-600), to($color-blue-800));
    background-image: linear-gradient(to bottom, $color-blue-600 0%, $color-blue-800 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr= '#ff62a8ea', endColorstr='#ff3583ca', GradientType=0);
    background-repeat: repeat-x;
    background-position: center top;
    -webkit-background-size: cover;
    background-size: cover;
  }
  form input[type=submit]:not(.disabled):hover, form input[type=button]:not(.disabled):hover, form button:not(.disabled):hover {
      background-color: inherit;
      background-image: inherit;
  }
  form input[type=email],
  form input[type=number],
  form input[type=password],
  form input[type=tel],
  form input[type=text],
  form input[type=url],
  form textarea {
    width: 100%;
    border: inherit;
    border-radius: inherit;
    font-size: inherit;
    font-family: inherit;
    outline: inherit;
    padding: inherit;
  }
  form button,
  form input[type=button],
  form input[type=submit] {
    border-radius: inherit;
    display: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    margin-right: inherit;
    padding: inherit;
    text-decoration: inherit;
    background-color: inherit;
    background-image: inherit;
    text-shadow: inherit;
    box-shadow: inherit;
    border: inherit;
    border-color: inherit;
    border-top-color: inherit;
    border-right-color: inherit;
    border-bottom-color: inherit;
    border-left-color: inherit;
    color: inherit;
    cursor: inherit;
  }
  .btn-block {
    display: block !important;
    width: 100% !important;
  }
  .form-group {
      margin-bottom: 2.429rem !important;
  }
  .btn-primary {
    color: #ffffff !important;
    background-color: $color-blue-600 !important;
    border-color: $color-blue-600 !important;
  }
  .btn {
    display: inline-block !important;
    font-weight: 300 !important;
    text-align: center !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    user-select: none !important;
    border: 1px solid transparent !important;
    padding: 0.429rem 1.072rem !important;
    font-size: 1rem !important;
    line-height: 1.57143 !important;
    border-radius: 0.215rem !important;
  }
  .panel {
    width: 400px;
    margin-bottom: 45px;
    background: #fff;
    border-radius: 4px;
    .panel-body {
      padding: 50px 40px 40px;
    }
    .brand-text {
      margin-top: 8px;
    }
  }
  .register {
    .panel {
      width: 50%;
      margin-bottom: 45px;
      background: #fff;
      border-radius: 4px;
      margin: 0 auto;
      .panel-body {
        padding: 50px 40px 40px;
      }
      .brand-text {
        margin-top: 8px;
      }
    }
  }
  .btn-group-lg > .btn,
  .btn-lg {
    padding: 0.715rem 1.286rem !important;
    font-size: 1.286rem !important;
    line-height: 1.33333 !important;
    border-radius: 0.286rem !important;
  }
  .btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
    background-color: #89bceb !important;
    border-color: #89bceb !important;
}
  form {
    margin: 45px 0 30px;
    a {
      margin-left: 20px;
    }
    .form-material {
      &.floating + .page-login form .form-material.floating {
        margin-top: 30px;
      }
      label {
        font-weight: 300;
        color: #a3afb7;
      }
    }
  }
}
@media (max-width: 1240px) {
  .page-login {
    .register {
      .panel {
        width: 70%;
        margin-bottom: 45px;
        background: #fff;
        border-radius: 4px;
        margin: 0 auto;
        .panel-body {
          padding: 50px 40px 40px;
        }
        .brand-text {
          margin-top: 8px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .page-login {
    .page-content {
      padding: 30px 20px;
    }
    .panel {
      width: auto;
      padding: 10px;
      .panel-body {
        padding: 35px 25px;
      }
    }
    .register {
      .panel {
        width: auto;
        padding: 10px;
        .panel-body {
          padding: 35px 25px;
        }
      }
    }
  }
}
