//  Address Display Component

.address-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  word-wrap: break-word;
  word-break: break-all;

  .nobreak {
    word-break: normal;
  }

  i.icon.fa {
    margin-right: .5em;
    opacity: .5;
  }

  .additional-content {
    margin-top: 1em;
  }

  span.negative-left-margin {
    margin-left: -.2em;
  }
}
