
@import "scss/variables";

$wb-font-path: "/fonts/web-icons/";

@import "scss/path";
//@import "less/core.less";

[class^="#{$wb-css-prefix}-"],
[class*=" #{$wb-css-prefix}-"] {
  font-family: '#{$wb-font-family}';

  @include make-icon();
}

@import "scss/icons";
