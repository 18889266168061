// Property Editor component patches

.js-property-editor {
  .editable-data {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    min-height: 2.5em;

    .displayed-value {
      display: inline-block;
      width: 100%;
      margin-right: .5em;
      word-wrap: break-word;
      word-break: break-all;
    }

    div.labels {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      span.label {
        margin: 0 .2em;
      }
    }

    input {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 .75em;
    }
  }
}
