// Name:            Tooltip
//
// Component:       `tooltip`
//
// Modifiers:       `tooltip-primary`
//                  `tooltip-success`
//                  `tooltip-info`
//                  `tooltip-warning`
//                  `tooltip-danger`
//                  `tooltip-rotate`
//                  `tooltip-scale`
//
// Markup:
//
// <!-- tooltip -->
// <div class="tooltip">
//   <div class="tooltip-arrow"></div>
//   <div class="tooltip-inner"></div>
// </div>
//
// ========================================================================

// Variables
// ========================================================================

$tooltip-arrow-width:                  8px !default;

$tooltip-primary-color:                $inverse !default;
$tooltip-primary-bg:                   $brand-primary !default;

$tooltip-success-color:                $inverse !default;
$tooltip-success-bg:                   $brand-success !default;

$tooltip-info-color:                   $inverse !default;
$tooltip-info-bg:                      $brand-info !default;

$tooltip-warning-color:                $inverse !default;
$tooltip-warning-bg:                   $brand-warning !default;

$tooltip-danger-color:                 $inverse !default;
$tooltip-danger-bg:                    $brand-danger !default;

// Mixins
// ========================================================================

@mixin tooltip-variant($color, $bg){
  .tooltip-inner {
    color: $color;
    background-color: $bg;
  }

  &.bs-tether-element-attached-top .tooltip-arrow,
  &.tooltip-top .tooltip-arrow {
    border-bottom-color: $bg;
  }

  &.bs-tether-element-attached-right .tooltip-arrow,
  &.tooltip-right .tooltip-arrow {
    border-left-color: $bg;
  }

  &.bs-tether-element-attached-bottom .tooltip-arrow,
  &.tooltip-bottom .tooltip-arrow {
    border-top-color: $bg;
  }

  &.bs-tether-element-attached-left .tooltip-arrow,
  &.tooltip-left .tooltip-arrow {
    border-right-color: $bg;
  }
}

// Component: Tooltip
// ========================================================================

.tooltip-inner {
  padding: 6px 12px;
}

// Color Modifier
// ========================================================================

// Modifier: `tooltip-primary`
// -------------------------

.tooltip-primary {
  @include tooltip-variant($tooltip-primary-color, $tooltip-primary-bg);
}

// Modifier: `tooltip-success`
// -------------------------

.tooltip-success {
  @include tooltip-variant($tooltip-success-color, $tooltip-success-bg);
}

// Modifier: `tooltip-info`
// -------------------------

.tooltip-info {
  @include tooltip-variant($tooltip-info-color, $tooltip-info-bg);
}

// Modifier: `tooltip-warning`
// -------------------------

.tooltip-warning {
  @include tooltip-variant($tooltip-warning-color, $tooltip-warning-bg);
}

// Modifier: `tooltip-danger`
// -------------------------

.tooltip-danger {
  @include tooltip-variant($tooltip-danger-color, $tooltip-danger-bg);
}

// Modifier: `tooltip-rotate`
// ========================================================================

// .tooltip-rotate {
//   animation: tooltip-rotate3d 1s ease 0.1s forwards;
//   opacity: 0;
// }

// @-webkit-keyframes tooltip-rotate3d {
//   0% {
//       opacity: 0;
//       -webkit-transform: rotate(15deg);
//               transform: rotate(15deg);
//   }
//   100% {
//       opacity: 1;
//       -webkit-transform: rotate(0deg);
//               transform: rotate(0deg);
//   }
// }


// @keyframes tooltip-rotate3d {
//   0% {
//       opacity: 0;
//       transform: rotate(15deg);
//   }
//   100% {
//       opacity: 1;
//       transform: rotate(0deg);
//   }
// }

// Modifier: `tooltip-scale`
// ========================================================================

// .tooltip-scale {
//   animation: tooltip-scale3d 1s ease 0s forwards;
// }

// @-webkit-keyframes tooltip-scale3d {
//   0% {
//       opacity: 0;
//       -webkit-transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
//               transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
//   }
//   100% {
//       opacity: 1;
//       -webkit-transform: scale3d(1, 1, 1) translate(50%, 50%);
//               transform: scale3d(1, 1, 1) translate(50%, 50%);
//   }
// }

// @keyframes tooltip-scale3d {
//   0% {
//       opacity: 0;
//       transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
//   }
//   100% {
//       opacity: 1;
//       transform: scale3d(1, 1, 1) translate(50%, 50%);
//   }
// }

