// Button-icon component

button[type="button"] {
  &.no-title {
    padding-right: 0;

    span.btn-label {
      margin-right: 0;
    }
  }
}
