code {
  border: 1px solid $code-border-color;
}

// add from---v3
// Blocks of code
pre {
  padding: (($line-height-computed - 1px) / 2);
  color: $pre-color;
  border: 1px solid $pre-border-color;
  border-radius: $border-radius;
}
// add end
