
.site-gridmenu {
  position: fixed;
  top: $site-navbar-height;
  bottom: 0;
  // height: 100%;
  // $height: 100% - #{$site-navbar-height} !default;
  padding: 20px 0;
  background-color: $site-gridmenu-bg;
  z-index: $zindex-navbar-fixed;

  opacity: 0;
  visibility: hidden;

  .js > & {
    transition: opacity .5s ease 0s, visibility 0s;
  }

  .site-gridmenu-active > & {
    @include media-breakpoint-down(sm) {

    }

    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease 0s;

    ul {
      opacity: 1;
      transform: rotateX(0deg);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    opacity: .4;
    transform: translateY(-25%) rotateX(35deg);
    transition: transform .5s ease 0s, opacity .5s ease 0s;
  }

  li {
    float: left;
    width: 50%;
    text-align: center;

    > a {
      display: block;
      padding: 30px 15px;
      color: $site-gridmenu-color;
    }

    .icon {
      display: block;
      margin-bottom: 10px;
      font-size: 32px;
    }

    &:hover > a {
      color: $site-gridmenu-hover-color;
      cursor: pointer;
      text-decoration: none;
      background-color: $site-gridmenu-hover-bg;
    }
  }
}

body.site-gridmenu-active {
  overflow: hidden;
}

.site-gridmenu-toggle {
  &:after {
    opacity: 0;
    font-family: '#{$wb-font-family}';
    font-size: $site-gridmenu-toggle-size;

    transition: opacity 0.15s;

    @include make-icon();

    position: relative;
    right: -3px;
    top: -1px;

    content: '#{$wb-var-chevron-down-mini}';

    .site-gridmenu-active & {
      //.rotate(90deg);
      opacity: 1;
    }
  }
}
