// Collapsible-table-row component

tr.collapsible-table-row {
  td {
    padding: 0 !important;

    .collapsible-table-row-container {
      display: block;
      position: relative;
      padding: 0;
      margin: 0;
      font-size: .9em;

      .collapsible-table-row-heading {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        cursor: pointer;

        span.collapsible-table-row-caret {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding: 0 1em;

          i.icon {
            transform: rotate(0deg);
            transition: transform .3s ease;
          }

          &.open {
            i.icon {
              transform: rotate(90deg);
            }
          }
        } //! .collapsible-table-row-caret

        span.collapsible-table-row-title {
          font-weight: bold;
          margin: .5em 0;
          @include no-select;
        }
      } //! .collapsible-table-row-heading

      .collapsible-table-row-content {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
        padding: 0;

        opacity: 0;
        transition: height .3s, opacity .3s;
        will-change: height;

        &.open {
          opacity: 1;
        }

        .panel {
          margin: 0;

          table thead tr th,
          table tbody tr td {
            padding: .25em 1.5em !important;
          }
        }
      }
    } //! .collapsible-table-row-container
  } //! td
} //! .collapsible-table-row
