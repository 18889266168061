//
// Order Timeline Component
//

$timeline-line-thickness: .8em !default;

.order-timeline-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-basis: 65%;
  padding-top: 1em;
  padding-right: 1em;

  &.state {
    justify-content: flex-end;
    flex-basis: auto;

    padding-right: 2.75em;
  }

  > .ember-view {
    position: absolute;
  }

  span.label.label-round {
    color: $color-black-600;
    border-color: transparentize($color-black-600, .7);
    cursor: pointer;
    margin-right: 1.5em;

    transition: opacity .3s;

    &.disabled, &[disabled] {
      opacity: .3;
      cursor: not-allowed;

      i.fa.pointer {
        cursor: not-allowed;
      }
    }
  }
}

.order-timeline {
  display: block;
  position: relative;
  max-width: 75em;

  margin: 6em 5em;
  flex-grow: 1;
  box-sizing: border-box;
  color: darken($label-info-bg, 10%);
  transition: color .5s;

  &.label-default {
    background: transparent;
    color: darken($label-default-bg, 10%);
  }

  &.label-primary {
    background: transparent;
    color: darken($label-primary-bg, 10%);
  }

  &.label-success {
    background: transparent;
    color: darken($label-success-bg, 10%);
  }

  &.label-info {
    background: transparent;
    color: darken($label-info-bg, 10%);
  }

  &.label-warning {
    background: transparent;
    color: darken($label-warning-bg, 10%);
  }

  &.label-danger {
    background: transparent;
    color: darken($label-danger-bg, 10%);
  }

  &.disabled {
    &:before, .line, .order-timeline-steps span {
      opacity: .5;
      pointer-events: none;
    }

    .order-timeline-steps span {
      opacity: .25;
    }

    .order-timeline-steps .order-timeline-step {
      color: $color-grey-400;
    }
  }

  span.label {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: .75em 1.75em;

    font-size: 1.1em;
    font-weight: bold;
    transform: translate(-50%, -50%);
    border-radius: 2em;
    z-index: 600;

    i.fa {
      margin-right: .5em;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - #{$timeline-line-thickness / 2});
    width: 100%;
    height: $timeline-line-thickness;

    border-radius: $timeline-line-thickness;
    background-color: $color-grey-300;
  }

  .line {
    display: block;
    position: absolute;
    top: calc(50% - #{$timeline-line-thickness / 2});

    width: 100%;
    height: $timeline-line-thickness;
    background-color: currentColor;
    border-radius: $timeline-line-thickness;

    transform: scaleX(0);
    transform-origin: left;
    transition: transform .5s;
  }

  .order-timeline-steps {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    overflow: visible;

    .order-timeline-step {
      display: block;
      position: relative;
      bottom: calc(100% + 1em);
      width: #{$timeline-line-thickness * 1.5};
      height: #{$timeline-line-thickness * 1.5};
      margin: 0 2em;
      box-sizing: content-box;

      color: currentColor;
      background-color: currentColor;
      border: #{$timeline-line-thickness * .5} solid white;
      border-radius: 50%;
      box-shadow: 0 0 0 -1em currentColor;
      z-index: 300;

      transition: box-shadow .3s;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        position: absolute;
        display: block;
        width: 30em;

        top: calc(100% + #{$timeline-line-thickness});
        left: calc(-15em + .6em);
        white-space: nowrap;
        text-align: center;
        color: $color-grey-800;

        transition: opacity .5s, color .5s, font-weight .5s;
      }

      &.current {
        position: relative;
        box-shadow: 0 0 0 #{$timeline-line-thickness * .25} currentColor;

        & ~ .order-timeline-step {
          color: $color-grey-400;
        }

        span {
          color: $color-black-600;
          font-weight: bold;
        }
      } //! &.current
    } //! .order-timeline-step
  } //! .order-timeline-steps
}

.wrapper.cuke-state-display {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span.order-state-wrapper {
    position: relative;
    padding: .75em 1.5em;

    text-decoration: none;
    background-color: transparent;
    color: darken($label-default-bg, 10%);

    border-radius: 3em;
    transition: background-color .3s, color .3s;

    &:not(.disabled) {
      border: 1px solid currentColor;

      &:hover, &:active {
        background: currentColor;

        .order-state {
          .value, .dot {
            color: $color-white;
          }
        }
      }
    }

    &.label-default {
      color: darken($label-default-bg, 10%);
    }

    &.label-primary {
      color: darken($label-primary-bg, 10%);
    }

    &.label-success {
      color: darken($label-success-bg, 10%);
    }

    &.label-info {
      color: darken($label-info-bg, 10%);
    }

    &.label-warning {
      color: darken($label-warning-bg, 10%);
    }

    &.label-danger {
      color: darken($label-danger-bg, 10%);
    }

    &.disabled {
      &:before {
        opacity: 0;
      }

      .order-state {
        .value {
          color: $color-grey-600;
        }
      }
    }

    .order-state {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      background-color: transparent;

      .value {
        margin-right: .5em;

        background-color: transparent;
        color: currentColor;
        font-weight: bold;

        transition: color .3s;

        @include no-select;
      }

      .dot {
        display: block;
        position: relative;
        width: 1em;
        height: 1em;
        margin: .5em 1em;
        margin-right: 0;

        border-radius: 50%;
        color: currentColor;
        background: currentColor;

        transition: color .3s;

        &:before, &:after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 1.75em;
          height: 1.75em;
          transform: translate(-50%, -50%);

          background: currentColor;
          border-radius: 50%;
          opacity: .4;
        }

        &:after {
          width: 2.5em;
          height: 2.5em;

          opacity: .25;
        }
      }
    }
  }
}
