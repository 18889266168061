// Text input groups
// -------------------------
.input-group-addon {
  transition: border .25s linear, color .25s linear, background-color .25s linear;
}


.input-group-btn {
  .btn {
    padding: 7px 10px;

    > .icon {
      vertical-align: bottom;
    }
  }

  .dropdown-toggle.btn .caret {
    margin-left: 2px;
  }

  &:last-child {
    > .btn,
    > .btn-group {
      z-index: $zindex-base;
    }
  }
}


.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  @extend .form-control-lg;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  @extend .form-control-sm;
}
