// Price display component

.price-display {
  position: relative;
  box-sizing: content-box;

  &.row {
    padding: .3em !important;
    margin: 0;

    &.bold {
      font-weight: bold;
      font-size: 1.25em;
      border-top: 1px solid transparentize($color-black, .75);
    }

    &.padded {
      padding: .3em 1em !important;
    }

    [class^="col-"] {
      position: relative;
      padding: 0;

      i.icon {
        opacity: .75;

        &.faint {
          opacity: .25;
        }
      }
    }
  }

  .price-display-value-container {
    position: relative;

    span.price-display-value {
      position: relative;

      &.negative:before {
        content: '-';
      }
    }
  }
}
