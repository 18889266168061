// Order details component && order details page specific styles

.order-details {
  padding: 1em 2em;

  [class^="col-"] {
    &.controls {
      text-align: right;
      padding: 0;

      .modal {
        text-align: left;
      }
    }

    .order-details-property {
      display: block;
      position: relative;
      padding: 2em 0;

      &:first-child {
        padding-top: 0;
      }

      h5 {
        text-transform: uppercase;
        font-weight: normal;
        font-size: .9em;
        opacity: .8;

        i.icon {
          font-size: 1.25em;
        }
      }

      .content {
        padding-left: 1em;

        .order-details-date-label {
          margin-left: -.25rem;
          font-size: .9em;
          font-style: italic;
          opacity: .75;
        }

        > span > .ember-view {
          position: relative;
          display: inline-block;
          width: 100%;
        }

        .js-property-editor [class*='col-'] {
          width: 100%;
          padding: 0;
        }
      }
    }
  }

  .tokenfield {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    padding: 0;
    padding-right: .5em;
    padding-bottom: .5em;

    .token {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      height: auto;
      padding: .1em .5em;
      margin: .5em;
      margin-right: 0;
      margin-bottom: 0;
      max-width: calc(100% - .5em);

      a.close {
        padding-right: .25em;
        margin-left: .5em;
        line-height: normal;
        height: auto;
      }
    }

    > .ember-view > .ember-view > button.btn {
      height: auto;
      margin: .5em;
      margin-right: 0;
      margin-bottom: 0;

      .btn-label {
        height: auto;
        padding: .3em .25em;
      }
    }
  }
} //! .order-details

.order-details-tabs {
  .panel.panel-bordered {
    .panel-body {
      padding: 2em 0;

      .masonry-grid-editable {
        padding: 0 3em;
      }

      .row {
        margin-left: 0;
        margin-right: 0;
        padding: 0 1em;

        &.padded {
          padding: 0 3.5em;
        }

        &.flex-line {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          > span {
            margin: 0 1em;
            white-space: nowrap;
            font-weight: bold;
          }

          > .ember-view {
            flex-basis: 100%;
            margin-right: 1.25em;
            font-weight: normal;
          }
        }
      }

      .js-order-details-order-event td:last-child {
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }

  ul.nav.nav-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: nowrap;
    white-space: nowrap;

    overflow-x: auto;
    overflow-y: hidden;
  }
}

.panel-heading.large {
  min-height: auto;
  border-bottom: none;

  > .ember-view {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    > .panel-actions {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding-right: 2em;
      right: 0;
      top: 0;
      max-width: 35em;
      margin: 1em 0;
    }
  }
}

a.package-creator-large,
a.packing-slip-generator-large,
a.object-generator-large,
a.remove-order-from-run-large,
a.object-duplicator-large,
a.address-creator-large {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: .6em 1.5em;
  margin: 1em 0;
  width: 100%;

  font-size: .9em;
  font-weight: bold;
  border: 1px solid $color-green-600;
  border-radius: 3px;
  color: $color-white;
  background-color: $color-green-600;
  text-decoration: none;
  text-transform: uppercase;

  transition: background-color .3s, color .3s;

  &.danger {
    border: 1px solid $color-red-600;
    background-color: $color-red-600;

    &:hover, &:focus {
      background-color: $color-red-700;
      border: 1px solid $color-red-700;
    }

    &:active {
      background-color: $color-red-800;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:hover, &:focus, &:active {
    text-decoration: none;
  }

  &:hover, &:focus {
    background-color: $color-green-700;
    color: $color-white;
  }

  &:active {
    background-color: $color-green-800;
    color: $color-white;
  }

  i.icon {
    margin-right: 1em;
    padding: .15em .2em;
    font-size: 2.5em;
    flex-shrink: 1;

    &.fa-barcode {
      border: 2px solid transparentize($color-white, .25);
      border-radius: .1em;
      transform: scale(.95);

      transition: border .3s;

      &:before {
        position: relative;
        display: block;

        transform-origin: center;
        transform: scale(1.1) translateY(.075em);

        transition: transform .3s;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;

        width: 0%;
        height: 1px;
        left: .2em;
        bottom: .15em;

        border: 1px dotted currentColor;
        opacity: 0;

        transition: width .5s, opacity .5s;
        transition-timing-function: linear, ease;
        will-change: width;
      }
    }

    &.fa-truck:not(.no-anim) {
      transform: translateX(-.1em) scaleX(-1) skewX(0deg);
      transition: transform .6s;

      &:after {
        content: '';
        display: block;
        position: absolute;

        width: .75em;
        height: .075em;
        top: .35em;
        right: 0em;
        transform: translateX(-.3em);

        background-color: currentColor;
        border-radius: .1em;
        box-shadow: -.05em .15em 0 currentColor,
                    -.1em .3em 0 currentColor;
        opacity: .2;
        transition: transform .6s, opacity .6s;
      }
    }

    &.fa-print:not(.no-anim) {
      &:before {
        opacity: 1;

        transition: opacity .8s;
      }

      &:after {
        content: '\f15c';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.75, .83);
        opacity: 0;

        transition: transform .8s, opacity .3s;

        z-index: 10;
      }
    }

    &.fa-plus:not(.no-anim), &.fa-clone:not(.no-anim) {
      &:before {
        position: relative;
        display: block;
        transform: scale(1);
        opacity: 1;
        line-height: .9em;

        transition: transform .3s, opacity .3s;
      }

      &:after {
        content: '\f075';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;

        transition: transform .3s, opacity .3s;
      }

      &.hover-address:after {
        content: '\f041';
      }
    }

    &.fa-repeat:not(.no-anim) {
      &:before {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1em;
        height: 1em;

        transform: rotate(0deg);
        transition: transform 0s;
      }
    }

    &.fa-trash:not(.no-anim) {
      height: 1.25em;

      &:before {
        position: absolute;
        overflow: hidden;
        height: .29em;

        transform-origin: bottom left;
        transform: rotate(-25deg);

        transition: transform .25s;
      }

      &:after {
        content: '\f1f8';
        display: flex;
        align-items: flex-end;
        position: absolute;
        overflow: hidden;
        height: .75em;
        bottom: .1em;

        z-index: -1;
      }
    }

    &.fa-paper-plane:not(.no-anim) {
      display: block;
      position: relative;
      transform: scale(.8);
    }
  }

  &:hover:not(.no-anim), &:focus:not(.no-anim), &:active:not(.no-anim) {
    i.icon.fa-barcode {
      border: 2px solid transparentize($color-white, .25);

      &:before {
        transform: scale(1) translateY(0em);
      }

      &:after {
        width: 72%;
        opacity: .5;

        transition: width 1s, opacity .3s;
      }
    }

    i.icon.fa-truck {
      transform: translateX(0) scaleX(-1) skewX(2deg);

      &:after {
        transform: translateX(0);
        opacity: .5;
      }
    }

    i.icon.fa-print {
      &:before {
        opacity: .5;
      }

      &:after {
        transform: translate(-50%, -25%) scale(.75, .83);
        opacity: 1;
      }
    }

    i.icon.fa-plus, i.icon.fa-clone {
      &:before {
        transform: scale(0);
        opacity: 0;
      }

      &:after {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }

    i.icon.fa-repeat {
      &:before {
        transform: rotate(360deg);
        transition: transform 1s;
      }
    }

    i.icon.fa-trash {
      animation-name: animationTrashShake;
      animation-duration: .4s;
      animation-delay: .1s;
      animation-timing-function: linear;
      animation-iteration-count: 1;

      &:before {
        transform: rotate(0deg);
      }
    }

    i.icon.fa-paper-plane {
      animation-name: animationPaperPlaneFly;
      animation-duration: .4s;
      animation-delay: 0;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
    }
  }

  span {
    flex-grow: 1;
    text-align: center;
  }
}

a.object-duplicator-large,
a.address-creator-large {
  display: inline-flex;
  width: auto;
  margin: 1em 2em !important;
}

.panel.panel-bordered.in-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;

  > .ember-view:not(:first-child) {
    flex-grow: 1;
  }

  .panel-actions {
    padding: 0 2em;
    margin-top: 0;

    > div.ember-view {
      margin-right: 0;
    }
  }
}

h3.panel-title {
  .origin-ref {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1em;

    font-size: .8em;
    opacity: .8;

    &:before {
      content: '/';
      display: block;
      position: relative;
      margin-right: .8em;

      color: $color-black;
      font-weight: normal;
      font-size: 1.25em;
      opacity: .5;
    }

    > .ember-view {
      margin-right: .5em;
      font-weight: bold;
    }

    a {
      text-decoration: underline;
      color: $color-black-500;
    }
  }

  .alert {
    margin: 0;
    margin-left: 1em;
  }
}

@keyframes animationTrashShake {
  from, to {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(-.05em);
  }
  50% {
    transform: translateX(.05em);
  }
}

@keyframes animationPaperPlaneFly {
  from, to {
    transform: scale(.8) translate(0, 0);
    opacity: 1;
  }
  45% {
    transform: scale(.8) translate(1em, -1em);
    opacity: 0;
  }
  55% {
    transform: scale(.8) translate(-1em, 1em);
    opacity: 0;
  }
}
