[class^="icheckbox_"] + label,
[class^="iradio_"] + label {
  margin: 0 0 0 15px;
}

/* blue */
.icheckbox_flat-blue,
.iradio_flat-blue {
  display: inline-block;
  // *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  background: url("/images/components/blue.png") no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_flat-blue {
  background-position: 0 0;
}

  .icheckbox_flat-blue.checked {
    background-position: -22px 0;
  }

  .icheckbox_flat-blue.disabled {
    background-position: -44px 0;
    cursor: default;
  }

  .icheckbox_flat-blue.checked.disabled {
    background-position: -66px 0;
  }

.iradio_flat-blue {
  background-position: -88px 0;
}

  .iradio_flat-blue.checked {
    background-position: -110px 0;
  }

  .iradio_flat-blue.disabled {
    background-position: -132px 0;
    cursor: default;
  }

  .iradio_flat-blue.checked.disabled {
    background-position: -154px 0;
  }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_flat-blue,
  .iradio_flat-blue {
    background-image: url("/images/components/blue@2x.png");
    -webkit-background-size: 176px 22px;
    background-size: 176px 22px;
  }
}
