.page-content {
	.dropdown-menu {
	  width: auto;
	}
}
.blocks-dropdowns {
	> li {
	  margin-bottom: 50px;
	}
	.dropdown-menu {
	  width: 100%;
	}
}

@include media-breakpoint-up(lg) {
  .blocks-dropdowns > li {
    max-width: 300px;
  }
}
