// editable-table
// ===================================================

table tbody {
  input.error {
    border: 1px solid $brand-danger;
    outline-offset: 0;
    outline: none;
  }
  input,
  input:focus {
    position: absolute;
    left: 0;
    top: -8px;
    padding: 7px;
    text-align: start;
    font-style: normal;
    font-variant: normal;
    font-weight: 300;
    font-stretch: normal;
    /* font-size: 14px; */
    /* line-height: 22px; */
    font-family: Roboto, sans-serif;
    width: 100%;
    border-width: 1px 0 0;
    border-style: solid none none;
    border-color: rgb(228, 234, 236) rgb(118, 131, 143) rgb(118, 131, 143);
    border: 1px solid $brand-primary !important;
    outline-offset: 0 !important;
    outline: none !important;
  }
  td:focus {
    outline: none;
  }
  span.editable-data,
  div.editable-data {
    display: block;
    position: relative;
    cursor: pointer;
  }
  div.pointer::after {
    display: block;
    width: 5px;
    height: 5px;
    float: right;
    background-color: $brand-primary;
  }
}
