@mixin transform($transform){
  -ms-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}

@mixin transform-style($transform-style){
  -ms-transform-style: $transform-style;
  -webkit-transform-style: $transform-style;
  transform-style: $transform-style;
}

@mixin translate($x,$y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9 only
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}
