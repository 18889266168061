// Order Creation page layout

.alert.alert-dismissible.alert-order-create {
  position: relative;
  margin: 2em 0;
  margin-top: 0;

  .close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 1em;
  }
}

.js-order-create-page {
  position: relative;
  overflow: visible;

  .order-create-preview-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5em .75em;
    margin: .5em 2em;
    margin-left: 0;

    text-decoration: none;
    border-radius: 1.5em;
    color: $color-blue-800;
    border: 1px solid $color-blue-800;
    background-color: transparent;

    transition: color .2s, background-color .2s;

    i.icon {
      font-size: 1.3em;
    }

    span {
      padding-left: 0;
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;

      transition: max-width .3s, padding-left .3s;
      will-change: max-width;

      @include no-select;
    }

    &:hover, &:focus, &:active {
      color: white;
      background-color: $color-blue-800;

      span {
        padding-left: .5em;
        max-width: 18ch;
      }
    }

    &:active {
      background-color: darken($color-blue-800, 15%);
    }
  }

  .container-fluid.order-create {
    .modal {
      .alert-margin {
        margin: 1em 2em;
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;

      [class*="col-"] {
        &.no-padding {
          padding-left: 0;
          padding-right: 0;

          .address-display {
            display: inline-flex;
            word-wrap: break-word;
            word-break: break-all;
          }
        }

        &.dummy-header:before {
          content: '';
          display: block;
          position: relative;
          width: 100%;
          height: 2.65em;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        ul.nav.nav-tabs {
          height: 2.65em;
        }

        .order-create-tabs {
          position: relative;
          height: 100%;

          .js-order-create-details {
            .bootstrap-datetimepicker-widget {
              ul.list-unstyled {
                margin-bottom: 0;
              }

              .picker-switch {
                table {
                  width: 100%;

                  td {
                    padding: 0;

                    a {
                      display: inline-block;
                      width: 50%;
                      padding: .5em 0;
                      text-align: center;
                      cursor: pointer;
                    }
                  }
                }
              }

              tr td {
                padding: .5em .75em;
                text-align: center;

                span {
                  &.month, &.year, &.decade {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 33%;
                    margin: 0;
                  }

                  &.decade {
                    width: 50%;
                  }

                  &:not([class]), &[class=""] {
                    display: none;
                  }
                }
              }

              .datepicker-months,
              .datepicker-years,
              .datepicker-decades {
                min-width: 15vw;
              }
            }
          }

          > .ember-view {
            position: relative;
            height: 100%;
            box-sizing: border-box;

            > .ember-view {
              position: relative;
              height: calc(100% - .25em);
              box-sizing: border-box;
            }
          }

          .ember-view {
            > .panel {
              position: relative;
              height: 100%;
              margin-bottom: 0;

              > .ember-view {
                position: relative;
                height: 100%;
              }

              .panel-body {
                position: relative;
                height: 100%;
                padding: 0;

                > .container-fluid {
                  position: relative;
                  height: 100%;
                  padding: 1em;
                  padding-right: 0;
                  padding-bottom: 5.5em;

                  .row {
                    position: relative;

                    &.bordered {
                      margin-bottom: 2em;

                      &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        height: 1px;
                        width: 70%;
                        left: 15%;
                        bottom: -1em;
                        pointer-events: none;
                        background-color: rgba(0, 0, 0, .075);
                      }
                    }

                    &.padded {
                      padding-top: .5em;
                      padding-bottom: .5em;

                      .property-label {
                        position: relative;

                        .sub {
                          position: relative;
                          font-size: .8em;
                          opacity: .8;
                        }
                      }
                    } //! &.padded
                  } // !.row
                }

                > ul.pagination {
                  position: absolute;
                  bottom: 1.5em;
                  left: 0;
                  margin: 0;
                }
              }
            }
          }

          > .ember-view {
            height: calc(100% - 2.5em);
          }
        }
      }
    }

    .order-create-summary {
      display: block;
      position: relative;
      width: calc(100% - 4em);
      margin: 2em;
      padding: .5em 0;
      box-sizing: border-box;

      background-color: white;
      box-shadow: 0 .2em 1em rgba(0, 0, 50, .15);
      border-radius: .5em;

      &.wide {
        margin: 0;
        padding: 0;
        width: 100%;
        box-shadow: none;
      }

      > h4 {
        padding-left: 1em;
        padding-right: 1em;
      }

      .table-compact {
        font-size: .95em;

        table thead tr th,
        table tbody tr td {
          padding-top: .5em;
          padding-bottom: .5em;
        }
      }

      .container-fluid {
        .row {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          margin: 0;
          margin-bottom: 1em;

          [class^="col-"] {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: .5em;

            &:first-child {
              padding-right: .5em;
            }
            &:last-child {
              padding-left: .5em;
              border-left: 1px solid rgba(0, 0, 0, .25);
            }

            .no-address {
              text-transform: uppercase;
              font-size: .8em;
              opacity: .35;
            }
          }
        }
      }
    } //! .order-create-summary
  }

  .order-create-tabs {
    ul.nav.nav-tabs {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      flex-wrap: nowrap;
      white-space: nowrap;

      overflow-x: auto;
      overflow-y: hidden;
    }

    .panel.panel-bordered {
      box-shadow: none;

      .container.container-centered {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  .js-order-create-shipping-address,
  .js-order-create-billing-address {
    .row > [class^="col-"] > h5 {
      font-weight: bold;
    }
  }
}
