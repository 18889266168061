// Name:            Table
//
// Component:       `table`
//
// Modifiers:       `table-default`
//                  `table-primary`
//                  `table-success`
//                  `table-info`
//                  `table-warning`
//                  `table-danger`
//                  `table-dark`
//                  `table-gray`
//                  `table-bordered`
//
// ========================================================================

// Component: Table
// ========================================================================

.table {
  color: $gray-dark;
  > thead > tr > th, > tfoot > tr > th {
    font-weight: $font-weight-bold;
    color: $gray-darker;
  }
  > thead > tr > th {
    border-bottom: 1px solid $table-border-color;
  }
  > tbody + tbody {
    border-top: 1px solid $table-border-color;
  }
  a {
    text-decoration: underline;
  }

  th, td {
    > .checkbox-custom:only-child {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
    }
  }

  thead:first-child {
    th {
      border-top: none;
    }
  }
  .table-success,
  .table-warning,
  .table-danger,
  .table-info {
    color: $color-white;
    a {
      color: $color-white;
    }
  }

  .cell-30 {
    width: 30px;
  }
  .cell-40 {
    width: 40px;
  }
  .cell-50 {
    width: 50px;
  }
  .cell-60 {
    width: 60px;
  }
  .cell-80 {
    width: 80px;
  }
  .cell-100 {
    width: 100px;
  }
  .cell-120 {
    width: 120px;
  }
  .cell-130 {
    width: 130px;
  }
  .cell-150 {
    width: 150px;
  }
  .cell-180 {
    width: 180px;
  }
  .cell-200 {
    width: 200px;
  }
  .cell-250 {
    width: 250px;
  }
  .cell-300 {
    width: 300px;
  }
}

// add style
th {
  font-weight: 400;
}

table thead {
  position: relative;

  .floating-actions {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    position: absolute;
    top: 0;
    right: 0;
    height: 3.5em;

    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 .75em;
      text-align: center;

      text-decoration: none;
      color: $gray-darker;

      &.success {
        color: $color-green;
      }

      &:last-child {
        padding-right: 1.25em;
      }

      &.button {
        background-color: $gray-darker;
        color: white;
        padding: .75em;
        margin: .75em;
        border-radius: .25em;
        transition: background-color .2s;

        &.success {
          background-color: $color-green;

          &:active {
            background-color: $color-green-800;
          }
        }
      }

      > i.icon {
        font-size: 1.2em;
      }
    }
  }
}
// add end

// Color Modifier
// ========================================================================

.table-primary,
.table-success,
.table-info,
.table-warning,
.table-danger,
.table-dark {
  thead tr {
    color: $inverse;
  }
}

// Modifier: `table-default`
// -------------------------

.table-default {
  thead tr {
    // color: $gray-darker;
    background: $bg-color-base;
  }
}

// Modifier: `table-primary`
// -------------------------

.table-primary {
  thead tr {
    background: $brand-primary;
  }
}

// Modifier: `table-success`
// -------------------------

.table-success {
  thead tr {
    background: $brand-success;
  }
}

// Modifier: `table-info`
// -------------------------

.table-info {
  thead tr {
    background: $brand-info;
  }
}

// Modifier: `table-warning`
// -------------------------

.table-warning {
  thead tr {
    background: $brand-warning;
  }
}

// Modifier: `table-danger`
// -------------------------

.table-danger {
  thead tr {
    background: $brand-danger;
  }
}

// Modifier: `table-dark`
// -------------------------

.table-dark {
  thead tr {
    background: $gray-darker;
  }
}

// Modifier: `table-gray`
// -------------------------

.table-gray {
  thead tr {
    color: $gray-darker;
    background: $gray-light;
  }
}

// Modifier: `table-bordered`
// ========================================================================

.table-bordered {
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 1px;
    }
  }

  > thead:first-child {
    > tr:first-child {
      > th {
        border: 1px solid $table-border-color;
      }
    }
  }
}

// Modifier: `table-section`
// ========================================================================
.table-section {
  & + tbody {
    display: none
  }

  &-arrow {
    @include make-icon();
    text-align: center;
    position: relative;
    font-family: '#{$wb-font-family}';

    transition: transform 0.15s;

    @include make-icon();

    &:before {
      content: '#{$wb-var-chevron-down}';
    }
  }

  &.active {
    tr {
      background-color: $table-bg-active;
    }

    + tbody {
      display: table-row-group;
    }

    .table-section-arrow {
      transform: rotate(-180deg);
    }
  }
}

// add from---v3
// table col[class*="col-"]

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}
// add end
