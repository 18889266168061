
.site-navbar {
  background-color: $site-navbar-header-bg;

  .navbar-header {
    background-color: transparent;
    color: $site-navbar-header-color;

    .navbar-toggler {
      color: $site-navbar-header-color;
    }

    .hamburger {
      &:before,
      &:after,
      .hamburger-bar {
        background-color: $site-navbar-header-color;
      }
    }

    .navbar-brand {
      color: $site-navbar-brand-color;
      font-family: $brand-font-family;
      cursor: pointer;
    }

    @include media-breakpoint-up(md) {
      width: $site-menubar-unfold-width;
    }
  }

  .navbar-container {
    @include media-breakpoint-up(md) {
      margin-left: $site-menubar-unfold-width;
    }

    background-color: $inverse;
  }

  &.navbar-inverse {
    .navbar-container {
      background-color: transparent;
    }
  }
}
