// Padding Helpers
// ========================================================================
.padding-0 {
  padding: 0 !important;
}
.padding-3 {
  padding: 3px !important;
}
.padding-5 {
  padding: 5px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-15 {
  padding: 15px !important;
}
.padding-20 {
  padding: 20px !important;
}
.padding-25 {
  padding: 25px !important;
}
.padding-30 {
  padding: 30px !important;
}
.padding-35 {
  padding: 35px !important;
}
.padding-40 {
  padding: 40px !important;
}
.padding-45 {
  padding: 45px !important;
}
.padding-50 {
  padding: 50px !important;
}
.padding-60 {
  padding: 60px !important;
}
.padding-70 {
  padding: 70px !important;
}
.padding-80 {
  padding: 80px !important;
}

// padding vertical
// ---------------
.padding-vertical-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.padding-vertical-3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.padding-vertical-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.padding-vertical-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.padding-vertical-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.padding-vertical-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.padding-vertical-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.padding-vertical-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.padding-vertical-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.padding-vertical-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.padding-vertical-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.padding-vertical-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.padding-vertical-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.padding-vertical-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.padding-vertical-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

// padding horizontal
// -----------------
.padding-horizontal-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.padding-horizontal-3 {
  padding-right: 3px !important;
  padding-left: 3px !important;
}
.padding-horizontal-5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.padding-horizontal-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.padding-horizontal-15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.padding-horizontal-20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.padding-horizontal-25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.padding-horizontal-30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}
.padding-horizontal-35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.padding-horizontal-40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.padding-horizontal-45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}
.padding-horizontal-50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}
.padding-horizontal-60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}
.padding-horizontal-70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}
.padding-horizontal-80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}
// padding top
// ----------
.padding-top-0 {
  padding-top: 0 !important;
}
.padding-top-3 {
  padding-top: 3px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-top-35 {
  padding-top: 35px !important;
}
.padding-top-40 {
  padding-top: 40px !important;
}
.padding-top-45 {
  padding-top: 45px !important;
}
.padding-top-50 {
  padding-top: 50px !important;
}
.padding-top-60 {
  padding-top: 60px !important;
}
.padding-top-70 {
  padding-top: 70px !important;
}
.padding-top-80 {
  padding-top: 80px !important;
}

// padding bottom
// -------------
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.padding-bottom-3 {
  padding-bottom: 3px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-bottom-30 {
  padding-bottom: 30px !important;
}
.padding-bottom-35 {
  padding-bottom: 35px !important;
}
.padding-bottom-40 {
  padding-bottom: 40px !important;
}
.padding-bottom-45 {
  padding-bottom: 45px !important;
}
.padding-bottom-50 {
  padding-bottom: 50px !important;
}
.padding-bottom-60 {
  padding-bottom: 60px !important;
}
.padding-bottom-70 {
  padding-bottom: 70px !important;
}
.padding-bottom-80 {
  padding-bottom: 80px !important;
}

// padding left
// -----------
.padding-left-0 {
  padding-left: 0 !important;
}
.padding-left-3 {
  padding-left: 3px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-left-35 {
  padding-left: 35px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}
.padding-left-45 {
  padding-left: 45px !important;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.padding-left-60 {
  padding-left: 60px !important;
}
.padding-left-70 {
  padding-left: 70px !important;
}
.padding-left-80 {
  padding-left: 80px !important;
}

// padding right
// ------------
.padding-right-0 {
  padding-right: 0 !important;
}
.padding-right-3 {
  padding-right: 3px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-right-35 {
  padding-right: 35px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}
.padding-right-45 {
  padding-right: 45px !important;
}
.padding-right-50 {
  padding-right: 50px !important;
}
.padding-right-60 {
  padding-right: 60px !important;
}
.padding-right-70 {
  padding-right: 70px !important;
}
.padding-right-80 {
  padding-right: 80px !important;
}

@include media-breakpoint-down(sm) {
  .padding-xs-0 {
    padding: 0 !important;
  }
}

@include media-breakpoint-up(md) {
  .padding-sm-0 {
    padding: 0 !important;
  }
}

@include media-breakpoint-up(lg) {
  .padding-md-0 {
    padding: 0 !important;
  }
}

@include media-breakpoint-up(xl) {
  .padding-lg-0 {
    padding: 0 !important;
  }
}
